import { SelectProps } from 'antd';
import { FormItem } from 'modules/common/components';
import RegionSelect from 'modules/locations/components/RegionSelect/inex';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PermissionsEnum, RoleOutput, UserRole } from 'types.d';

type ProductRegionSelectProps = {
  control: Omit<UseControllerProps<any>, 'name'>;
  disabled: boolean;
  userRole: RoleOutput;
  error?: string;
} & SelectProps;

const ProductRegionSelect: FC<ProductRegionSelectProps> = ({
  control,
  disabled,
  userRole,
  ...restProps
}) => {
  const { t } = useTranslation('admin.EditUserForm');
  const user = useCurrentUser();
  const allowedRoles = [
    UserRole.Admin,
    UserRole.MedicalDirector,
    UserRole.CollectionTeam,
  ];
  const isAllowToDisplay = allowedRoles.includes(userRole.value);
  const isAllowToUpdate = user.isCan(
    PermissionsEnum.UpdateProductLocationPreference
  );

  return isAllowToDisplay ? (
    <FormItem label={t('productRegionPreference')}>
      <RegionSelect
        {...restProps}
        controlled={{
          ...control,
          name: 'productRegionPreference',
        }}
        disabled={disabled || !isAllowToUpdate}
      />
    </FormItem>
  ) : null;
};

export default ProductRegionSelect;
