import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface IHeadProps {
  collectionDate: Date;
}

const Head: FC<IHeadProps> = ({ collectionDate }) => {
  const { t } = useTranslation('donor.FormsToPrint.IDEXXSubmissionForm');
  const formattedDate =
    collectionDate &&
    collectionDate
      .toLocaleDateString()
      .split('.')
      .map((part) => part.trim());

  return (
    <div className={styles.container}>
      <div></div>
      <div className={styles.content}>
        <div className={styles.infoContainer}>
          <div className={styles.forLabs}>{t('forLabs')}</div>
          <div className={styles.collectionDate}>
            <p>{t('collectionDate')}</p>{' '}
            {formattedDate && (
              <p>
                <span className={styles.datePart}>{formattedDate[0]}</span>
                <span className={styles.slash}>/</span>
                <span className={styles.datePart}>{formattedDate[1]}</span>
                <span className={styles.slash}>/</span>
                <span className={styles.datePart}>{formattedDate[2]}</span>
              </p>
            )}
          </div>
        </div>
        <div className={styles.logoContainer}>
          <div className={styles.logo}></div>
          <p className={styles.logoText}>{t('head')}</p>
          <p className={styles.logoNumber}>
            <b>{t('number')}</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Head;
