import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Button, Col } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Routes } from 'config/routes';
import {
  FormItem,
  Spin,
  Alert,
  ControlledCheckbox,
  Link,
} from 'modules/common/components';
import { NavigationStateKeys } from 'modules/navigation/enums';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSignUpMutation } from 'types.d';

import { signUpFormFields } from './fields';
import { signUpSchema, SignUpSchemaType } from './schema';
import styles from './styles.module.scss';

const SignUpForm: FC = () => {
  const { t } = useTranslation('user.SignUpForm');
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  const [signUp, signUpMutation] = useSignUpMutation({
    onCompleted: () => {
      navigate(Routes.Login, {
        state: { [NavigationStateKeys.Message]: t('pleaseConfirmEmail') },
        replace: true,
      });
    },
    onError: ({ message }) => {
      setErrorMessage(message);
    },
  });
  const { control, handleSubmit, formState, watch, trigger } =
    useForm<SignUpSchemaType>({
      resolver: yupResolver(signUpSchema),
      mode: 'onChange',
    });
  const password = watch('password');

  const removeErrorMessage = () => setErrorMessage(undefined);

  const signUpHandler = handleSubmit(async (data) => {
    const { confirmPassword, ...signUpData } = data;
    await signUp({
      variables: {
        input: signUpData,
      },
    });
  });

  const alreadyHaveAnAccountHandler = () => {
    navigate(Routes.Login);
  };

  useEffect(() => {
    if (
      !Boolean(formState.errors.password) &&
      password &&
      formState.touchedFields.confirmPassword
    ) {
      trigger('confirmPassword');
    }
  }, [formState.errors.password]);

  return (
    <Spin spinning={signUpMutation.loading}>
      {errorMessage && (
        <Alert
          message={errorMessage}
          type="error"
          closable
          afterClose={removeErrorMessage}
        />
      )}
      <Form>
        {signUpFormFields.map(({ label, name, placeholder, Component }) => {
          const fieldErrorMessage = !formState.isValid
            ? formState.errors[name]?.message
            : '';
          return (
            <FormItem
              key={name}
              label={label}
              extra={fieldErrorMessage}
              validateStatus={fieldErrorMessage && 'error'}
            >
              <Component
                placeholder={placeholder}
                controlled={{ name, control }}
              />
            </FormItem>
          );
        })}
        <Col span={24}>
          <ControlledCheckbox
            controlled={{
              control: control,
              name: 'privacyPolicyAgreement',
            }}
          >
            <Paragraph>
              {t('agreeToNavbb')}{' '}
              <Link to={Routes.PrivacyPolicy} target="_blank">
                {t('privacyPolicy')}
              </Link>
            </Paragraph>
          </ControlledCheckbox>
        </Col>
        <div className={styles.buttonsContainer}>
          <Button
            disabled={!formState.isValid}
            onClick={signUpHandler}
            type="primary"
          >
            {t('signUp')}
          </Button>
          <Button type="link" onClick={alreadyHaveAnAccountHandler}>
            {t('alreadyHaveAnAccount')}
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default SignUpForm;
