import { InviteUserRole, UserRole } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('user.ValidationErrors');

export const usernameSchema = yup
  .string()
  .strict(true)
  .trim(t('mustBeTrimmed'))
  .required(t('required', { field: t('name') }))
  .test({
    test: (value) => (value ? !(value.length < 2) : true),
    message: t('moreThan', { min: 2 }),
  })
  .max(30, t('lessThan', { max: 30 }))
  .test({
    test: (value) => (value ? new RegExp(/^[a-z ,.'-]+$/i).test(value) : true),
    message: t('onlyAlphabets'),
  }); //Alphabets & spaces;

export const userLastNameSchema = yup
  .string()
  .strict(true)
  .trim(t('mustBeTrimmed'))
  .required(t('required', { field: t('lastName') }))
  .test({
    test: (value) => (value ? !(value.length < 2) : true),
    message: t('moreThan', { min: 2 }),
  })
  .max(30, t('lessThan', { max: 30 }))
  .test({
    test: (value) => (value ? new RegExp(/^[a-z ,.'-]+$/i).test(value) : true),
    message: t('onlyAlphabets'),
  }); //Alphabets & spaces;

export const emailSchema = yup
  .string()
  .required(t('required', { field: t('email') }))
  .max(50, t('lessThan', { max: 50 }))
  .email(t('emailMustBeValid'));

export const passwordSchema = yup
  .string()
  .required(t('required', { field: t('password') }))
  .matches(/(?=.*[0-9])/g, t('numberRequired')) //Must include number
  .matches(/(?=.*[a-z])(?=.*[A-Z])/g, t('upperLowercaseLetterRequired')) //Must include letters
  .matches(/(?=.*?[#?:!@$%^&*-.,/|\\`~])/g, t('specialRequired')) //Must include special symbols
  .min(8, t('moreThan', { min: 8 }))
  .max(64, t('lessThan', { max: 64 }));

// RegEx below is used for matching such phone number:
// - (123) 456-7890
export const phoneNumberSchema = yup
  .string()
  .required(t('pleaseEnterPhoneNumber'))
  .matches(
    /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/g,
    t('phoneNumberDoesNotMatch')
  );

export const inviteUserRoleSchema = yup
  .mixed<InviteUserRole>()
  .oneOf(Object.values(InviteUserRole))
  .required(t('required', { field: t('role') }));

export const userRoleSchema = yup
  .mixed<UserRole>()
  .oneOf(Object.values(UserRole))
  .required(t('required', { field: t('role') }));

export const contactPreferencesSchema = yup
  .string()
  .transform((val) => (val === null ? undefined : val))
  .max(100, t('lessThan', { max: 100 }));

export const productRegionPreferenceSchema = yup.string();
