import * as yup from 'yup';

import { petSchema, PetSchemaType } from './petSchema';
import { userSchema, UserSchemaType } from './userSchema';

export type RegisterPetSchemaType = {
  user: UserSchemaType;
  pet: PetSchemaType;
  privacyPolicyAgreement?: boolean;
};

export const registerPetSchema: yup.SchemaOf<RegisterPetSchemaType> =
  yup.object({
    user: userSchema,
    pet: petSchema,
    privacyPolicyAgreement: yup.boolean(),
  });
