import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Grid, message, Row, Spin, Table } from 'antd';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  PermissionsEnum,
  useDeleteRegionMutation,
  useGetRegionsQuery,
  useUpdateRegionMutation,
} from 'types.d';

import { getColumns } from './columns';
import { ICreateRegionsFormSchema, createRegionsFormSchema } from './schema';
import styles from './styles.module.scss';

import CreateRegionsForm from '../CreateRegionForm';

const RegionsTable: FC = () => {
  const { t } = useTranslation('user.LocationsTable');
  const { xs } = Grid.useBreakpoint();
  const [isMobile, setMobile] = useState<boolean>(false);
  const pagination = usePagination({ urlPagination: false });
  const currentUser = useCurrentUser();
  const [locationName, setLocationName] = useState<string | undefined>();
  const [locationAccountingCode, setLocationAccountingCode] = useState<
    string | undefined
  >();
  const createNewLocationAllowed = currentUser.isCan(
    PermissionsEnum.CreteLocation
  );
  const deleteLocationAllowed = currentUser.isCan(
    PermissionsEnum.DeleteLocation
  );
  const updateLocationAllowed = currentUser.isCan(
    PermissionsEnum.UpdateLocation
  );

  useEffect(() => {
    xs !== undefined && setMobile(xs);
  }, [xs]);

  const [deleteRegion, deleteRegionMutation] = useDeleteRegionMutation({
    refetchQueries: ['getRegions'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      message.success(t('locationDeleted'));
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const [updateRegion, updateRegionMutation] = useUpdateRegionMutation({
    refetchQueries: ['getRegions'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      currentUser.refetchData();
      message.success(t('locationUpdated'));
      createRegionsForm.reset();
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const createRegionsForm = useForm<ICreateRegionsFormSchema>({
    resolver: yupResolver(createRegionsFormSchema),
    mode: 'onChange',
    defaultValues: {
      id: undefined,
      name: undefined,
    },
  });
  const editableKey = createRegionsForm.watch('id');
  const inputtedName = createRegionsForm.watch('name');
  const inputtedAccountingCode = createRegionsForm.watch('accountingCode');

  const setEditableKey = (
    locationId?: string,
    currentLocationName?: string,
    currentAccountingCode?: string
  ) => {
    createRegionsForm.reset();
    setLocationName(currentLocationName);
    setLocationAccountingCode(currentAccountingCode);
    createRegionsForm.setValue('id', locationId as string, {
      shouldValidate: true,
    });
  };
  const regionsQuery = useGetRegionsQuery();
  const updateLocationHandler = createRegionsForm.handleSubmit((formData) => {
    updateRegion({
      variables: {
        input: {
          ...formData,
        },
      },
    });
  });
  const deleteLocationHandler = (id: string) => {
    deleteRegion({ variables: { input: { id } } });
  };
  const regions = regionsQuery.data?.getRegions;
  const isLoading = [
    deleteRegionMutation,
    updateRegionMutation,
    regionsQuery,
  ].some(({ loading }) => loading);
  const isNewValues =
    locationName !== inputtedName ||
    locationAccountingCode !== inputtedAccountingCode;
  const savingIsDisabled =
    !createRegionsForm.formState.isValid ||
    !createRegionsForm.formState.isDirty ||
    !isNewValues;

  const locationTableColumns = useMemo(
    () =>
      getColumns({
        control: createRegionsForm.control,
        setEditableKey,
        editableKey,
        onSaveChanges: updateLocationHandler,
        savingIsDisabled,
        onDelete: deleteLocationHandler,
        isAllowedToDelete: deleteLocationAllowed,
        isAllowedToEdit: updateLocationAllowed,
        errors: createRegionsForm.formState.errors,
        isMobile,
      }),
    [
      createRegionsForm.control,
      inputtedName,
      editableKey,
      createRegionsForm.formState.isValid,
      createRegionsForm.formState.isDirty,
      isMobile,
    ]
  );

  return (
    <Row gutter={[0, 20]}>
      {createNewLocationAllowed && (
        <Col span={24}>
          <CreateRegionsForm />
        </Col>
      )}
      <Col span={24} className={styles.tableWrapper}>
        <Spin spinning={isLoading}>
          <Table
            rowKey={({ id }) => id}
            columns={locationTableColumns}
            dataSource={regions}
            pagination={pagination.config}
            size="small"
          />
        </Spin>
      </Col>
    </Row>
  );
};

export default RegionsTable;
