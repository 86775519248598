import { Col, message, Row } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { Button } from 'modules/common/components';
import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { DonorReportOutput, useGetDonorsReportLazyQuery } from 'types.d';

import { donorReportHeaders } from './headers';

const DonorReports: FC = () => {
  const { t } = useTranslation('reports.DonorReports');
  const fileName = `donors_${format(new Date(), config.SHORT_DATE_FORMAT)}`;
  const [getDonorsList, getDonorsListQuery] = useGetDonorsReportLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        limit: 10000,
      },
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const isLoading = getDonorsListQuery.loading;
  const receivedDonors = (getDonorsListQuery.data?.getDonorsReport ||
    []) as DonorReportOutput[];

  const mappedDonors = receivedDonors.map(({ __typename, ...restFields }) => {
    const notes = restFields.notes
      .map((note) => `${note.user.lastName}: ${note.text}`)
      .join(', ');

    return {
      ...restFields,
      notes,
      becameReservedDonorDate: restFields.becameReservedDonorDate
        ? format(new Date(restFields.becameReservedDonorDate), 'MM/dd/yyyy')
        : '',
      rabiesDate: restFields.rabiesDate
        ? format(new Date(restFields.rabiesDate), 'MM/dd/yyyy')
        : '',
      distemperDate: restFields.distemperDate
        ? format(new Date(restFields.distemperDate), 'MM/dd/yyyy')
        : '',
      retiredDate: restFields.retiredDate
        ? format(new Date(restFields.retiredDate), 'MM/dd/yyyy')
        : '',
      nextDonationDate: restFields.nextDonationDate
        ? format(new Date(restFields.nextDonationDate), 'MM/dd/yyyy')
        : '',
      labWorkDue: restFields.labWorkDue
        ? format(new Date(restFields.labWorkDue), 'MM/dd/yyyy')
        : '',
    };
  });

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Button
          onClick={() => getDonorsList()}
          loading={isLoading}
          disabled={isLoading}
          type="primary"
        >
          {t('generateCsvReport')}
        </Button>
      </Col>
      <Col span={24}>
        {Boolean(mappedDonors.length) && (
          <CSVLink
            headers={donorReportHeaders}
            data={mappedDonors}
            filename={fileName}
          >
            {`${fileName}.csv`}
          </CSVLink>
        )}
      </Col>
    </Row>
  );
};

export default DonorReports;
