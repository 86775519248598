import config from 'config';
import { differenceInDays } from 'date-fns';
import { IAlertConfig } from 'modules/donations/components/CreateDonationAlerts/interface';
import { CreateDonationSchemaType } from 'modules/donations/components/CreateDonationForm/schema';
import { IVitalsParameters } from 'modules/donor/components/UpdateVitalsParametersModal/interface';
import { DonationOutput, LabworkOutput } from 'types';
import { getTranslation } from 'utils/getTranslation';

const t = getTranslation('donor.CreateDonationAlerts');

//TODO: Separate this function on two, one is for getting errorTypes and second one is for getting the error it self.

export const getDonationAlerts = (
  parameters: IVitalsParameters,
  data: CreateDonationSchemaType,
  previousDonationData?: DonationOutput,
  lastLabwork?: LabworkOutput
): IAlertConfig[] => {
  const examination = data.vitalsSection.examination;
  return [
    {
      message: t('temperatureCheckMessage'),
      rule: () => {
        const isParametersLoaded =
          parameters?.TemperatureToStop?.min &&
          parameters?.TemperatureToStop?.max &&
          parameters?.TemperatureToCheck?.min &&
          parameters?.TemperatureToCheck?.max;

        if (isParametersLoaded) {
          const minStart = parameters.TemperatureToStop!.min as number;
          const minEnd = parameters.TemperatureToCheck!.min as number;
          const maxStart = parameters.TemperatureToCheck!.max as number;
          const maxEnd = parameters.TemperatureToStop!.max as number;
          const temperature = examination?.temperature;
          return (
            (temperature >= minStart && temperature <= minEnd) ||
            (temperature >= maxStart && temperature <= maxEnd)
          );
        }
        return false;
      },
      description: t('temperatureCheckDescription'),
    },
    {
      message: t('temperatureStopMessage'),
      rule: () => {
        const isParametersLoaded =
          parameters?.TemperatureToStop?.min &&
          parameters?.TemperatureToStop?.max;

        if (isParametersLoaded) {
          const min = parameters.TemperatureToStop!.min as number;
          const max = parameters.TemperatureToStop!.max as number;
          const temperature = examination?.temperature;
          return temperature < min || temperature > max;
        }
        return false;
      },
      description: t('temperatureStopDescription'),
    },
    {
      message: t('heartRateMessage'),
      rule: () => {
        const isParametersLoaded =
          parameters?.Pulse?.min && parameters?.Pulse?.max;

        if (isParametersLoaded) {
          const min = parameters.Pulse!.min as number;
          const max = parameters.Pulse!.max as number;
          const pulse = examination?.pulse;
          return pulse < min || pulse > max;
        }
        return false;
      },
      description: t('heartRateDescription'),
    },
    {
      message: t('pcvMessage'),
      rule: () => {
        const isParametersLoaded = parameters?.PackedCellVolume?.min;
        const packedCellVolume = examination?.packedCellVolume;
        const previousPackedCellVolume = previousDonationData?.packedCellVolume;

        if (isParametersLoaded) {
          const min = parameters.PackedCellVolume!.min as number;
          const max = parameters.PackedCellVolume!.max as number;

          const isNewLower = previousPackedCellVolume
            ? previousPackedCellVolume - packedCellVolume >= 10
            : false;

          return packedCellVolume < min || packedCellVolume > max || isNewLower;
        }

        return false;
      },
      description:
        previousDonationData?.packedCellVolume &&
        previousDonationData?.packedCellVolume -
          examination?.packedCellVolume >=
          10
          ? t('pcvPrevDescription')
          : t('pcvDescription'),
    },
    {
      message: t('tsMessage'),
      rule: () => {
        const isParameterLoaded = parameters?.TotalSolids?.min;

        if (isParameterLoaded) {
          const min = parameters.TotalSolids!.min as number;
          const max = parameters.TotalSolids!.max as number;
          const totalSolids = examination?.totalSolids;
          return totalSolids < min || totalSolids > max;
        }
        return false;
      },
      description: t('tsDescription'),
    },
    {
      message: t('weightMessage'),
      rule: () => {
        const isParameterLoaded = parameters?.Weight?.percentageDifference;

        if (isParameterLoaded && previousDonationData?.weight) {
          const percentage = parameters.Weight!.percentageDifference as number;
          const weight = examination?.weight;
          return (
            previousDonationData.weight - weight >=
            (previousDonationData.weight / 100) * percentage
          );
        }
        return false;
      },
      description: t('weightDescription'),
    },
    {
      message: t('overdueLabworkMessage'),
      rule: () => {
        if (lastLabwork) {
          const daysToLastLabwork = Math.abs(
            differenceInDays(new Date(lastLabwork.collectionDate), new Date())
          );

          return daysToLastLabwork > config.LAST_LABWORK_IN_DAYS_LIMIT;
        }
        return false;
      },
      description: t('overdueLabworkDescription'),
    },
    {
      message: t('noLabworkMessage'),
      rule: () => {
        return !lastLabwork;
      },
      description: t('noLabworkDescription'),
    },
  ];
};
