export default {
  InvitePage: {
    email: 'Email',
    enterEmail: 'Enter email',
    firstName: 'First name',
    enterFirstName: 'Enter first name',
    lastName: 'Last name',
    enterLastName: 'Enter last name',
    phone: 'Phone number',
    enterPhone: 'Enter phone number',
    role: 'Role',
    invite: 'Invite user',
    close: 'Close',
    pageTitle: 'Invite page',
    success: 'User has been invited!',
    emailInUse: 'Email is already in use',
    selectUserRole: 'Select user role',
    Errors: {
      required: '{{field}} is required!',
      moreThan: 'It must be more than {{min}} characters',
      lessThan: 'It must be less than {{max}} characters',
      onlyAlphabets: 'It must contain only alphabets',
      numberRequired: 'It must contain number',
      upperLowercaseLetterRequired: 'It must contain  upper/lowercase letter',
      specialRequired: 'It must contain special',
    },
  },
  AcceptInvitePage: {
    password: 'New password',
    enterPassword: 'Enter password',
    confirmPassword: 'Confirm password',
    acceptInvite: 'Accept invitation',
    cancel: 'Cancel',
    pageTitle: 'Accept Invite Page',
    approved: 'User with email "{{email}}" has been successfully approved!',
    passwordMustMatch: 'Passwords must match',
  },
  PermissionsPage: {
    addDonorToAppointment: 'Add donor to appointment',
    createTimeSlotOrAppointment: 'Create timeslot or appointment',
    updateOwnProfile: 'Update own profile',
    deleteAppointments: 'Delete appointments',
    removeDonorFromAppointment: 'Remove donor from appointment',
    shareLabworkResults: 'Share labwork results',
    updateOwnersEmail: "Update owner's email",
    updateProductInfo: 'Update product info',
    updateProductStatus: 'Update product status',
    updateVitalsChartParameters: 'Update vitals chart parameters',
    viewVitalsChartParameters: 'View vitals chart parameters',
    addUsers: 'Add new users',
    deleteUsers: 'Delete users',
    viewUsersList: 'View the list of users',
    changeUserRole: "Change users' roles at the system",
    viewOwnProfile: 'View own profile',
    updateUserEmail: "Update users' email",
    updateUserInfo: "Update users' info (first name, last name, phone number) ",
    managePermissions: 'Manage permissions',
    viewRequestsForApproval: 'View requests for approval',
    approveDisapproveRequests: 'Approve/disapprove requests',
    viewAppointments: 'View appointments',
    updateAppointments: 'Update appointments',
    cancelAppointments: 'Cancel appointments',
    viewTheHistoryOfAppointments: 'View the history of appointments',
    viewTasksSchedule: 'View task schedule',
    printTasksSchedule: 'Print task schedule',
    viewTasksScheduleOfSpecificUser: 'View task schedule of the specific user',
    viewTheListOfDonors: 'View the list of donors',
    updateDonorsData: 'Update donors’ data',
    archiveDonors: 'Archive donors',
    viewDonorsPictures: "View donor's pictures",
    uploadDonorsPictures: "Upload donor's pictures",
    deleteDonorsPictures: "Delete donor's pictures",
    viewLabworkResults: 'View labwork results',
    updateLabworkData: 'Update labwork data',
    deleteLabworkData: 'Delete labwork data',
    updateLabworkStatus: 'Update labwork status',
    viewSpecificDonationDataAtDonorProfile:
      'View specific donation data at the donor profile',
    addCommentsToTheDonationAtTheDonorProfile:
      'Add comments to the donation at the donor profile',
    viewVitalsChart: 'View vitals chart',
    changeDonorStatus: 'Change donor status',
    addNewDonation: 'Add new donation',
    updateDonationData: 'Update donation data',
    viewTheListOfOwners: 'View the list of owners',
    updateOwnersData: "Update owner's data",
    archiveOwners: 'Archive owners',
    viewTheListOfTrainingReserveDonors:
      'View the list of training reserve donors',
    updateTheTrainingReserveDonors: 'Update the training reserve donors',
    viewDonationDataCollectionPage: 'View donation data collection page',
    addProducts: 'Add products',
    createLabels: 'Create labels',
    updateDonationCollectionPage: 'Update donation collection page',
    viewProductList: 'View product list',
    makeProductsAvailable: 'Make products “Available”',
    viewListOfOrders: 'View the list of the orders',
    viewReports: 'View reports',
    receiveNotifications: 'Receive notifications',
    receiveEmailWithTaskScheduleEveryFriday:
      'Receive email with task schedule every Friday',
    receiveLabworkExpirationNotification:
      'Receive labwork expiration notification',
    permissions: 'Permissions',
    inventoryTeam: 'Inventory team',
    collectionTeam: 'Collection team',
    dogHandler: 'Dog handler',
    medicalDirector: 'Medical director',
    owner: 'Owner',
    pageTitle: 'Permissions page',
    success: 'Successfully changed!',
    admin: 'Admin',
    aollectionTeam: 'CollectionTeam',
    creteLocation: 'Create location',
    deleteLocation: 'Delete location',
    updateLocation: 'Update location',
    deleteProduct: 'Delete product',
    updateProduct: 'Update product',
    detachLabworkFile: 'Detach labwork file',
    approveDonationML: 'Approve Donation ML',
    donationFrequency: 'Update donation frequency',
    updateProductRegionPreference: 'Update product region preference',
  },
  UsersListPage: {
    number: '№',
    firstName: 'First name',
    lastName: 'Last name',
    contact: 'Contact',
    role: 'Role',
    createUser: 'Create User',
    users: 'Users',
    permissions: 'Permissions',
    pageTitle: 'Users',
    inviteUser: 'Invite user',
    removeSuccessfully: 'Successfully removed!',
    areYouSureYouWantToDeleteUser: 'Are you sure you want to delete this user?',
    yes: 'Yes',
    no: 'No',
    successfullyDeleted: 'Successfully deleted!',
    searchUser: 'Search by first name or last name',
    admin: 'Admin',
    collectionTeam: 'Collection team',
    dogHandler: 'Dog handler',
    inventoryTeam: 'Inventory team',
    medicalDirector: 'Medical director',
    owner: 'Owner',
    email: 'Email',
    me: '(Me)',
  },
  Navigation: {
    user: 'User',
    users: 'Users',
    requestsForApproval: 'Requests for approval',
    appointments: 'Appointments',
    invite: 'Invite page',
    schedule: 'Task schedule',
    donors: 'Donors',
    individualDonorList: 'Individual donor list',
    groupDonorList: 'Group donor list',
    trainingReserveDonors: 'Training reserve donors',
    appointmentsDonors: 'Cached donors',
    labwork: 'Labwork',
    owners: 'Owners',
    donationsSplashPage: 'Donations splash page',
    donationDataCollectionPage: 'Donations data collection page',
    productList: 'Product list',
    productCreatedPage: 'Product created page',
    orders: 'Orders',
    new: 'New',
    processed: 'Processed',
    reports: 'Reports',
    logout: 'Logout',
    admin: 'Admin',
    collectionteam: 'Collection team',
    doghandler: 'Dog handler',
    inventoryteam: 'Inventory team',
    medicaldirector: 'Medical director',
    owner: 'Owner',
    locations: 'Locations',
  },
  EditUserPage: {
    pageTitle: 'Edit user page',
  },
  EditUserForm: {
    edit: 'Edit user',
    close: 'Close',
    save: 'Save',
    success: 'User has been edited!',
    successfullySaved: 'Successfully saved!',
    letterHasBeenSentToNewEmail:
      'A letter with confirmation link has been sent to a new email!',
    productRegionPreference: 'Product Region Preference',
  },
  EditUserEmailForm: {
    email: 'Email',
    enterEmail: 'Enter email',
    letterHasBeenSentToNewEmail:
      'A letter with confirmation link has been sent to a new email!',
    successfullySaved: 'Successfully saved!',
  },
  EditUserRoleForm: {
    role: 'Role',
    roleHasBeenChanged: 'Role has been changed!',
    selectUserRole: 'Select user role',
  },
  EditUserInfoForm: {
    edit: 'Edit user',
    close: 'Close',
    save: 'Save',
    successfullySaved: 'Successfully saved!',
  },
};
