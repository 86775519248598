import { FC } from 'react';

const EngPolicyContent: FC = () => {
  return (
    <section>
      <header>
        <h1>
          <b>Privacy Policy for Canine Blood Donor Registration</b>
        </h1>
        <p>
          <strong>Effective Date:</strong> January 1, 2024
        </p>
        <p>
          At NAVBB (North American Veterinary Blood Bank), we are committed to
          protecting your privacy. This Privacy Policy outlines how we collect,
          use, and safeguard your personal information when you register your
          dog to become a blood donor or create a veterinary professional
          account.
        </p>
      </header>

      <main>
        <section>
          <h2>
            <b>For Dog Owners</b>
          </h2>

          <article>
            <h3>
              <b>
                <i>1. Information We Collect</i>
              </b>
            </h3>
            <p>
              When you complete the canine blood donor registration form, we may
              collect the following information:
            </p>
            <ul>
              <li>
                <strong>Owner Information:</strong> Name, address, phone number,
                email address, and emergency contact details.
              </li>
              <li>
                <strong>Dog Information:</strong> Name, breed, age, weight,
                health history, vaccination records, and relevant behavioral
                information.
              </li>
              <li>
                <strong>Veterinary Information:</strong> Name and contact
                details of your primary veterinarian.
              </li>
            </ul>
          </article>

          <article>
            <h3>
              <b>
                <i>2. How We Use Your Information</i>
              </b>
            </h3>
            <p>
              The information collected during the registration process will be
              used for the following purposes:
            </p>
            <ul>
              <li>
                <strong>Donor Program Coordination:</strong> To assess your
                dog's eligibility for our donor program and communicate with you
                regarding donation schedules, eligibility, and program updates.
              </li>
              <li>
                <strong>Health and Safety:</strong> To ensure the health and
                safety of your dog and those receiving blood products by
                tracking health data and monitoring donor participation.
              </li>
              <li>
                <strong>Veterinary Contact:</strong> We may contact your
                veterinarian for additional health information to verify
                eligibility or for post-donation care.
              </li>
              <li>
                <strong>Internal Record-Keeping:</strong> For compliance with
                local and federal regulations, including record-keeping for
                veterinary standards and blood safety.
              </li>
              <li>
                <strong>Communication:</strong> To provide updates, reminders,
                and important notifications related to your dog’s participation
                in the program.
              </li>
            </ul>
          </article>

          <article>
            <h3>
              <b>
                <i>3. Third-Party Sharing</i>
              </b>
            </h3>
            <p>
              Your information will not be sold or shared with third parties,
              except in the following circumstances:
            </p>
            <ul>
              <li>
                <strong>Veterinary Care Providers:</strong> Relevant health
                information may be shared with veterinary professionals involved
                in your dog’s care or treatment.
              </li>
              <li>
                <strong>Regulatory Compliance:</strong> Information may be
                shared as required by law, in response to a subpoena, or for
                compliance with veterinary and blood bank regulations.
              </li>
              <li>
                <strong>Service Providers:</strong> We may use third-party
                service providers (such as email or SMS providers) to assist
                with communications. These providers will only have access to
                the information necessary to perform their specific functions.
              </li>
            </ul>
          </article>

          <article>
            <h3>
              <b>
                <i>4. Consent to Communication (TCR Compliance)</i>
              </b>
            </h3>
            <p>
              By providing your contact information (phone number or email
              address), you consent to receiving communications from NAVBB,
              including:
            </p>
            <ul>
              <li>
                <strong>SMS/MMS and Calls:</strong> For appointment scheduling,
                reminders, and program updates. Message and data rates may
                apply.
              </li>
              <li>
                <strong>Email Communications:</strong> You may receive emails
                related to your dog’s eligibility, program updates, or marketing
                materials about NAVBB.
              </li>
            </ul>
            <p>
              You may opt-out of receiving marketing communications at any time
              by following the unsubscribe instructions in our emails or by
              contacting us directly. SMS opt-in and phone numbers collected for
              SMS purposes will not be shared with third parties or affiliates
              for marketing purposes.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>5. Data Security</i>
              </b>
            </h3>
            <p>
              We implement appropriate security measures to protect against
              unauthorized access, alteration, or destruction of your personal
              information. This includes encryption of sensitive information,
              secure storage systems, and limiting access to personal data to
              only those employees or contractors who require it to fulfill
              their job duties.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>6. Data Retention</i>
              </b>
            </h3>
            <p>
              We retain personal information for as long as necessary to provide
              services to your dog and comply with our legal obligations. If you
              or your dog leave the donor program, we will retain your data only
              as long as needed for regulatory and record-keeping purposes.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>7. Your Rights</i>
              </b>
            </h3>
            <p>You have the right to:</p>
            <ul>
              <li>
                Request access to the personal information we hold about you or
                your dog.
              </li>
              <li>
                Request corrections or updates to your personal information.
              </li>
              <li>Withdraw consent to communications, where applicable.</li>
              <li>
                Request that we delete your personal information, subject to our
                legal or regulatory obligations.
              </li>
            </ul>
          </article>

          <article>
            <h3>
              <b>
                <i>8. Changes to This Privacy Policy</i>
              </b>
            </h3>
            <p>
              We reserve the right to update or modify this Privacy Policy at
              any time. Changes will be posted on our website with the effective
              date of the new policy. We encourage you to review this policy
              regularly to stay informed of how we are protecting your
              information.
            </p>
          </article>
        </section>

        <section>
          <h2>
            <b>For Veterinary Professionals</b>
          </h2>

          <article>
            <h3>
              <b>
                <i>9. Who We Are</i>
              </b>
            </h3>
            <p>
              NVABB LLC (DBA NAVBB), located in Manassas, Virginia, operates the
              website: <a href="https://www.navbb.com">https://www.navbb.com</a>
              , which offers an online store for animal blood products, provides
              information for those enrolled or interested in our blood donor
              program, and connects our network of donors through social media
              and other online posts.
            </p>
            <p>
              This page informs website visitors regarding our policies
              concerning the collection, use, and disclosure of personal
              information for anyone deciding to use our services on our
              website.
            </p>
            <p>
              If you choose to use our services, you agree to the collection and
              use of information in accordance with this policy. The personal
              information we collect is used to provide and improve our
              services. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>10. What Personal Data We Collect and Why We Collect It</i>
              </b>
            </h3>
            <p>
              While using our services, we may require you to provide certain
              personally identifiable information, including but not limited to
              your name, phone number, and postal address. This information will
              be used to contact or identify you.
            </p>
            <h4>
              <b>E-Commerce Services:</b>
            </h4>
            <h4>
              <b>Log Data:</b>
            </h4>
            <p>
              Whenever you visit our Service, we collect information that your
              browser sends to us, called Log Data. This Log Data may include
              information such as your computer’s Internet Protocol (“IP”)
              address, browser version, pages of our website that you visit, the
              time and date of your visit, the time spent on those pages, and
              other statistics.
            </p>
            <p>
              <b>What We Collect and Store:</b> While you visit our site, we’ll
              track:
            </p>
            <ul>
              <li>
                <b>Products you’ve viewed:</b> This helps us show you products
                you’ve recently viewed.
              </li>
              <li>
                <b>Location, IP address, and browser type:</b> Used for purposes
                like estimating taxes and shipping.
              </li>
              <li>
                <b>Shipping address:</b> Required for estimating shipping costs
                and sending you the order.
              </li>
            </ul>
            <p>
              We’ll also use cookies to keep track of cart contents while you’re
              browsing our site.
            </p>
            <p>
              When you purchase from us, we’ll ask you for information including
              your name, billing address, shipping address, email address, phone
              number, credit card/payment details, and optional account
              information like username and password. We’ll use this information
              to:
            </p>
            <ul>
              <li>Send you information about your account and order.</li>
              <li>
                Respond to your requests, including refunds and complaints.
              </li>
              <li>Process payments and prevent fraud.</li>
              <li>Set up your account for our store.</li>
              <li>Comply with legal obligations, such as calculating taxes.</li>
              <li>Improve our store offerings.</li>
              <li>
                Send you marketing messages, if you choose to receive them.
              </li>
            </ul>
            <p>
              If you create an account, we will store your name, address, email,
              and phone number to populate the checkout for future orders. We
              generally store your information as long as necessary for the
              purposes we collected it and are not legally required to retain
              it. For example, we will store order information for five years
              for tax and accounting purposes, including your name, email
              address, and billing and shipping addresses.
            </p>
            <p>
              We will also store comments or reviews if you choose to leave
              them.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>11. Who on Our Team Has Access</i>
              </b>
            </h3>
            <p>
              Members of our team have access to the information you provide.
              For example, both Administrators and Shop Managers can access:
            </p>
            <ul>
              <li>
                <b>Order information:</b> What was purchased, when it was
                purchased, and where it should be sent.
              </li>
              <li>
                <b>Customer information:</b> Your name, email address, and
                billing and shipping information.
              </li>
            </ul>
            <p>
              Team members have access to this information to fulfill orders,
              process refunds, and support you.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>12. Payments</i>
              </b>
            </h3>
            <p>
              We accept payments through Elavon. When processing payments, some
              of your data will be passed to Elavon, including information
              required to process or support the payment, such as the purchase
              total and billing information. Please see the Elavon Privacy
              Policy for more details.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>13. Contact Forms</i>
              </b>
            </h3>
            <p>
              After submitting any contact forms provided on the website, we
              will collect and store your personal information (first and last
              name, phone number, email) to reach out with more information
              regarding NVABB LLC.
            </p>
            <p>
              If you do not wish to be contacted any longer, you may unsubscribe
              from our email lists or contact us at&nbsp;
              <a href="mailto:donate@navbb.com">donate@navbb.com</a> to request
              immediate removal from our contact lists. All users are
              automatically removed from our contact lists after a set period.
              We will not use your personal information submitted via contact
              forms for marketing purposes or share it with third parties.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>14. Cookies</i>
              </b>
            </h3>
            <p>
              If you leave a comment on our site, you may opt to save your name,
              email address, and website in cookies for convenience, so you
              don’t have to fill in your details again when leaving another
              comment. These cookies will last for one year.
            </p>
            <p>
              If you have an account and log in to this site, we will set a
              temporary cookie to determine if your browser accepts cookies.
              This cookie contains no personal data and is discarded when you
              close your browser.
            </p>
            <p>
              When you log in, we will also set up several cookies to save your
              login information and screen display choices. Login cookies last
              for two days, and screen options cookies last for a year. If you
              select “Remember Me,” your login will persist for two weeks. If
              you log out of your account, the login cookies will be removed.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>15. Embedded Content from Other Websites</i>
              </b>
            </h3>
            <p>
              Articles on this site may include embedded content (e.g., videos,
              images, articles, etc.). Embedded content from other websites
              behaves exactly as if the visitor has visited the other website.
              Note that these external sites are not operated by us, so we
              strongly advise reviewing the Privacy Policy of these websites. We
              have no control over, and assume no responsibility for the
              content, privacy policies, or practices of any third-party sites
              or services.
            </p>
            <p>
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with
              that embedded content, including tracking if you have an account
              and are logged in to that website.
            </p>
          </article>

          <article>
            <h3>
              <b>
                <i>16. Analytics</i>
              </b>
            </h3>
            <p>
              We use Google Analytics to track visitor traffic to our website
            </p>
          </article>
        </section>
      </main>

      <footer>
        <p>
          If you have any questions about this Privacy Policy or wish to
          exercise your privacy rights, please contact us at:
        </p>
        <address>
          NAVBB
          <br />
          9431 Main St
          <br />
          Manassas, VA 20110
          <br />
          Phone: <a href="tel:+17035747417">703-574-7417</a>
          <br />
          Email: <a href="mailto:donate@navbb.com">donate@navbb.com</a>
        </address>
      </footer>
    </section>
  );
};

export default EngPolicyContent;
