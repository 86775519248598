import { Col, Row } from 'antd';
import { DefaultClinicParams } from 'config/default-clinic-params';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import ContactSection from './components/ContactSection';
import Head from './components/Head';
import Specimens from './components/Specimens';
import styles from './styles.module.scss';
import { buildColumns } from './utils/build-column';
import {
  firstColumnFieldsConfig,
  fourthColumnFieldConfig,
  secondColumnFieldConfig,
  thirdColumnFieldConfig,
} from './utils/column-config';

import { RoutineTestFormSchemaType } from '../../RoutineTestForm/schema';
import { FormPage } from '../components/FormPage';

type PropTypes = {
  data: RoutineTestFormSchemaType & DefaultClinicParams;
};
const AthensVeterinaryDiagnostic = forwardRef<any, PropTypes>(
  ({ data }, ref) => {
    const { t } = useTranslation(
      'donor.FormsToPrint.AthensVeterinaryDiagnostic'
    );
    return (
      <FormPage classNames={styles.form} ref={ref}>
        <Head />
        <ContactSection data={data} />
        <Row className={styles.askingFillForm}>
          <p>
            <b>{t('pleaseFillOutForm')}</b>
          </p>
        </Row>
        <Row>
          <p className={styles.boxesTip}>{t('checkAppropriateBoxes')}</p>
        </Row>
        <Row>
          <Col span={6} className={styles.column}>
            <Row>
              <Row>
                <h3 className={styles.columnTitle}>
                  {t('cultureAndIdentification')}
                </h3>
              </Row>
              <Row>{buildColumns(firstColumnFieldsConfig)}</Row>
            </Row>
          </Col>
          <Col span={6} className={styles.column}>
            <Row>{buildColumns(secondColumnFieldConfig)}</Row>
          </Col>
          <Col span={6} className={styles.column}>
            <Row>{buildColumns(thirdColumnFieldConfig)}</Row>
          </Col>
          <Col span={6} className={styles.column}>
            <Row>{buildColumns(fourthColumnFieldConfig)}</Row>
            <Specimens />
          </Col>
        </Row>
        <Row className={styles.footer}>
          <span>{t('formNumber')}</span>
          <span>{t('revisionDate')}</span>
        </Row>
      </FormPage>
    );
  }
);

export default AthensVeterinaryDiagnostic;
