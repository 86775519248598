export default {
  ProductsPage: {
    products: 'Products',
  },
  ProductsPageTabs: {
    products: 'Products',
    dashboard: 'Dashboard',
  },
  ProductsDashboardPage: {
    dashboard: 'Dashboard',
  },
  ProductsDashboard: {
    dashboard: 'Dashboard',
    products: 'Products',
    woocommerceProducts_inStock: 'Woocommerce products (in stock)',
    woocommerceProducts_sold: 'Woocommerce products (sold)',
    lastMonth: 'Last month',
    month: 'Month',
    week: 'Week',
    year: 'Year',
    dea1Positive: 'DEA 1 Positive',
    dea1Negative: 'DEA 1 Negative',
    unknown: 'Unknown',
    available: 'Available',
    collected: 'Collected',
    discarded: 'Discarded',
    pending: 'Pending',
    processed: 'Processed',
    sold: 'Sold',
    bloodType: 'Blood type',
    status: 'Status',
    woocommerceCategory: 'Woocommerce category',
  },
  CreateProductModal: {
    donorId: 'Donor ID:',
    bloodType: 'Blood type:',
    quantity: 'Quantity:',
    donationDate: 'Donation date:',
    storageRecommendations: 'Storage requirements and recommendations',
    createProduct: 'Create product',
    productHasBeenCreated: 'Product has been created!',
    donationDataIsWrong: 'Donation data is wrong!',
  },
  ProductExpirationDates: {
    expirationDate: 'Expiration date:',
  },
  StorageRecommendations: {
    storeUprightBetween:
      'Store upright between {{fromF}}°F and {{toF}}°F ({{fromC}}°C and {{toC}}°C)',
    crossMatchBeforeTransfusion: 'Cross-match before transfusion',
    mixBeforeUse: 'Mix thoroughly before use',
    administerWithFilteredTransfusionSet:
      'Administer with a filtered transfusion set',
    doNotWhenExpired: 'Do not use after expiration date',
    doNotAddMedicationToPlasma: 'Do not add medication to the plasma',
    doNotAddMedicationToBlood: 'Do not add medication to the blood',
    storeFrozenBetween: 'Store frozen between {{fromC}}°C and {{toC}}°C',
    unlessUsingFP: '(unless using as FP not FFP)',
  },
  ProductsTable: {
    label: 'Label',
    donationId: 'Donation ID',
    orderId: 'Order ID',
    petName: "Pet's name",
    ownerLastName: "Owner's last name",
    expirationDate: 'Expiration date',
    product: 'Product',
    bloodType: 'Blood type',
    available: 'Available',
    collected: 'Collected',
    discarded: 'Discarded',
    pending: 'Pending',
    processed: 'Processed',
    sold: 'Sold',
    soldInfo: 'Sold info',
    canine: 'Canine',
    feline: 'Feline',
    mls: "ml's",
    region: 'Region',
    productAvailable: 'Product is available in store',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    unknown: 'Unknown',
    Header: {
      print: 'Print',
      searchProduct: 'Search product by donation ID',
    },
  },
  LabelInfo: {
    donationId: 'Donation ID',
    bloodType: 'Blood Type',
    volume: 'Volume',
    anticoagulant: 'Anticoagulant',
    donationDate: 'Donation Date',
    productRegion: 'Product Region',
  },
  AnticoagulantSelect: {
    selectAnticoagulant: 'Select anticoagulant',
  },
  LabelSizeSelect: {
    size: 'Select size',
  },
  Label: {
    expiresOn: 'Expires On',
    volume: 'Volume',
    product: 'Product',
    storageRecommendations: 'STORAGE REQUIREMENTS AND RECOMMENDATIONS',
    donationId: 'Donation ID',
    anticoagulant: 'Anticoagulant',
    forVeterinaryOnly: 'For Veterinary Use Only',
    pleaseContact:
      'Questions? Please contact donate@navbb.com or call {{contact}}',
    negative: 'Negative',
    positive: 'Positive',
    unknown: 'Unknown',
    dd: 'DD',
    feline: 'Feline',
    canine: 'Canine',
    species: 'Species',
  },
  ProductPage: {
    product: 'Product',
    productIdNotValid: 'Product id is not valid',
  },
  ProductCard: {
    orderDetails: 'Order details',
    updateProductInfo: 'Update product info',
    donationDetails: 'Donation details',
  },
  ProductDetails: {
    expirationDates: 'Expiration dates',
    bloodType: 'Blood type',
    volume: 'Volume',
    donorId: 'Donor ID',
    donationDate: 'DD',
    anticoagulant: 'Anticoagulant',
    productName: 'Product name',
    sold: 'Sold',
    discarded: 'Discarded',
    productHasBeenUpdated: 'Product status has been updated!',
    status: 'Status',
  },
  ProductTypeSelect: {
    selectProductType: 'Select product type',
  },
  CategorySelect: {
    selectProductCategory: 'Select product category',
  },
  DonationProductDescriptions: {
    value: 'Value',
    expirationDate: 'Expiration date',
    status: 'Status',
  },
  ChangeProductTrackingInfoModal: {
    productUpdated: 'Product has been updated',
    idRequired: 'Field cannot be empty',
    updateProductInfo: 'Update product info',
    woocommerceOrderId: 'Woocommerce order ID',
    verify: 'Verify',
    orderDoesNotExist: 'Order does not exist',
    orderIdShouldBeNumber: 'The order id must contain only numbers',
    orderIdRequired: 'Order id is required',
    productStatus: 'Product status',
    Footer: {
      update: 'Update',
      cancel: 'Cancel',
      untie: 'Untie',
    },
  },
  ProductDropdownActions: {
    productInfo: 'Product info',
    DeleteProductButton: {
      deleteProduct: 'Delete product',
      sureToDeleteProduct: 'Delete product?',
      yes: 'Yes',
      cancel: 'Cancel',
      productHasBeenDeleted: 'Product has been deleted',
    },
  },
  AvailabilityCheckbox: {
    makeAvailable: 'Are you sure to set available this product?',
    yes: 'Yes',
    no: 'No',
  },
};
