export const endocrinology = [
  { id: '851', description: 'Thyroid Panel 2—TT4, FT4' },
  { id: '852', description: 'Thyroid Panel 6—FT4, cTSH' },
  { id: '804', description: 'T4, Total (TT4)' },
  { id: '849', description: 'Free T4 (FT4)' },
  { id: '853', description: 'TSH, Endogenous' },
  { id: '119', description: 'ACTH Stimulation (One Pre, One Post)' },
  { id: '275', description: 'Dex Suppression (One Pre, Two Posts)' },
  { id: '245', description: 'Fructosamine' },
  { id: '806', description: 'Progesterone' },
];
