import {
  phoneNumberSchema,
  productRegionPreferenceSchema,
  usernameSchema,
} from 'modules/user/schemas';
import * as yup from 'yup';

import { EditUserFields } from './enum';

const { firstName, lastName, phoneNumber, productRegionPreference } =
  EditUserFields;

type EditUserInfoSchema = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  productRegionPreference?: string | null;
};

const editUserInfoSchema: yup.SchemaOf<EditUserInfoSchema> = yup.object({
  [firstName]: usernameSchema,
  [lastName]: usernameSchema,
  [phoneNumber]: phoneNumberSchema,
  [productRegionPreference]: productRegionPreferenceSchema.nullable(),
});

export { editUserInfoSchema };
export type { EditUserInfoSchema };
