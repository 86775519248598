export const stoneAnalysis = [
  {
    id: '90900',
    description: 'Stone Analysis, Quantitative (Crystallographic)',
  },
  { id: '910', description: 'Urinalysis □ Cysto □ Catheter □ Other' },
  { id: '994', description: 'Urine Protein:Creatinine Ratio' },
  {
    id: '2326',
    description: 'Urinalysis with Reflex UPC □ Cysto □ Catheter □ Other',
  },
];
