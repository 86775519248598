import config from 'config';
import { Routes } from 'config/routes';
import { getTranslation } from 'utils/getTranslation';

import { MenuOptionType } from '../types';

const t = getTranslation('navigation.ProfileSideMenuWrapper');

export const menuItemsOfOwner: MenuOptionType[] = [
  {
    key: Routes.Profile,
    label: t('profile'),
  },
  {
    key: Routes.Donors,
    label: t('donors'),
  },
  ...(config.ENABLED_APPOINTMENTS_PAGE
    ? [
        {
          key: Routes.Appointments,
          label: t('appointments'),
        },
      ]
    : []),
];
