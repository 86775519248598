import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import { getEntries } from 'utils/object/getEntries';

const donorHeadersConfig = {
  id: 'id',
  shortId: 'shortId',
  donation: {
    id: 'donationId',
  },
  anticoagulant: 'anticoagulant',
  expirationDate: 'expirationDate',
  status: 'status',
  storageRequirements: 'storageRequirements',
  value: 'value',
  woocommerceCategoryId: 'woocommerceCategoryId',
  woocommerceProductId: 'woocommerceProductId',
  regionPreference: { name: 'productRegion' },
};

const donorHeadersConfigEntries = getEntries(donorHeadersConfig);

export const donorReportHeaders: LabelKeyObject[] =
  donorHeadersConfigEntries.map(([key, label]) => ({ label, key }));
