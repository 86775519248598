export default {
  DonorApplicationForm: {
    dateOfBirth: 'Date of birth',
    petName: "What is your pet's name?",
    typePetsName: "Type pet's name",
    petAge: 'How old is your pet?',
    typePetsAge: `Type your pet's age (e.g. 2 and 1/2 years type 2)`,
    neutered: 'Is your pet spayed or neutered?',
    selectSpecie: 'Select specie',
    petWeight_Dog:
      'How much does your pet weigh? (35lb minimum required to safely donate)',
    petWeight_Cat:
      'How much does your pet weigh? (9.5lb minimum required to safely donate)',
    typePetsWeight:
      "Type your pet's weight (e.g. type 72 if your pet's weight is 72.4 lbs.)",
    hasItTransfusion:
      'Has your pet ever received any type of blood product transfusion before (blood or plasma)?',
    hasItHeartworms:
      'Has your pet had heartworms and developed a heart murmur or heart condition as a result of having heartworms?',
    doesItTakeMedications:
      'Is your pet currently taking prescription medications other than heartworm, flea, or tick preventative?',
    primaryVeterinarian: 'Who is your primary veterinarian?',
    transfusion:
      'Has your pet ever received any type of blood product transfusion before (blood or plasma)?',
    takeMedications:
      'Is your pet currently taking prescription medications other than heartworm, flea, or tick preventative?',
    petSpecies: 'What species is your pet?',
    gender: 'Is your pet a male or female?',
    selectGender: 'Select gender',
    yourAnswer: 'Your answer',
    submit: 'Submit',
    donorHasBeenAdded: 'Donor has been successfully added!',
    medicationHistory: 'Medication History',
    kindOfMedication: 'What medication are they taking?',
    medicationTerm: 'Long Term medication or temporary?',
    enterPetWeight: "Please enter pet's weight",
    pleaseSelectLocation: 'Please select location',
    medicationCommentary: 'Commentary on the medication course',
    lb: 'Lb',
    locationPreferences:
      'Where are you registering to donate? (the name of the location is listed on your registration card)',
    donorWithTransfusionNotAllowed:
      'As your pet has previously been a recipient of blood transfusions, pet is not eligible to donate blood. Thank you again for your interest in our program.',
    bloodType: 'Blood type',
    selectBloodType: 'Select blood type',
    breed: 'What breed is your pet?',
  },
  DonorApplicationPage: {
    donorApplication: 'Donor Application',
  },
  YesNoRadioGroup: {
    yes: 'Yes',
    no: 'No',
  },
  DonorProfilePage: {
    donorProfile: 'Donor profile',
    addNewDonation: 'Add new donation',
  },
  DonorVitalsChartPage: {
    donorVitalsChart: 'Donor vitals charts',
  },
  DonorPicturesPage: {
    donorPictures: 'Donor pictures ',
    donorArchived: 'Donor is archived!',
  },
  DonorLabworkPage: {
    donorLabwork: 'Donor labwork',
  },
  LabworkProfile: {
    title: 'Labwork profile',
  },
  LabworkPage: {
    title: 'Labwork',
  },
  DonorDonationsPage: {
    donorDonations: 'Donor donations',
  },
  DonorProfileCardTabs: {
    profile: 'Profile',
    pictures: 'Pictures',
    labwork: 'Labwork',
    donations: 'Donations',
    vitalsChart: 'Vitals charts',
    addNewDonation: 'Add new donation',
    routineTest: 'Routine test',
    notes: 'Notes',
  },
  CreateDonorPage: {
    createDonor: 'Create donor',
  },
  donorNotesPage: {
    donorNotes: 'Donor notes',
  },
  ControlledPetSpeciesSelect: {
    pickSpecies: "Please pick pet's species",
  },
  ControlledPetGenderSelect: {
    pickGender: "Please pick pet's gender",
  },
  RoutineTestGenderSelect: {
    pickGender: "Please pick pet's gender",
  },
  BloodTypeSelect: {
    selectBloodType: 'Please select blood type',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    unknown: 'Unknown',
  },
  CreateDonorForm: {
    OptionalSection: {
      distinguishableMarks: 'Distinguishable marks',
      enterDistinguishableMarks: 'Enter distinguishable marks',
      bloodType: 'Blood type',
      selectBloodType: 'Select blood type',
      donorType: 'Donor type',
      selectDonorType: 'Select donor type',
      team: 'Team',
      selectTeam: 'Select team for donor',
      breed: 'Breed',
      enterBreed: "Enter donor's breed",
      color: 'Color',
      enterColor: "Enter donor's color",
      comment: 'Comment',
      labwork: 'Labwork',
      livesSavedRatio: 'LSR (lives saved ratio)',
      leaveComment: 'Leave comment',
      microchip: 'Microchip #',
      enterComment: 'Enter comment',
      hasMicrochip: 'Has microchip?',
      teamRequired: 'Team is required',
      shorterThan:
        "'{{field}}' field must contain less than {{max}} characters",
    },
    RequiredSection: {
      dateOfBirth: 'Date of birth',
      years: 'Years',
      lb: 'Lb',
      kg: 'Kg',
      petName: "Pet's name",
      enterPetName: "Please enter pet's name",
      specie: 'Specie',
      petAge: "Pet's age",
      enterPetAge: "Please enter pet's age",
      petGender: "Pet's gender",
      petWeight: "Pet's weight",
      enterPetWeight: "Please enter pet's weight",
      neutered: 'Spayed or neutered?',
      lastName: 'Last name',
      enterOwnerName: "Please enter owner's name",
      primaryVeterinarian: 'Who is your primary veterinarian?',
      enterPrimaryVeterinarian: 'Enter you primary veterinarian',
      medicationHistory: 'Medication history',
      shorterThan:
        "'{{field}}' field must contain less than {{max}} characters",
      enterMedicationHistory: "Please enter pet's medication history",
      takeMedications:
        'Does your pet currently take any medications other than heartworm, flea or tick preventatives?',
      pleaseSelectLocation:
        'Please select or enter location which is closer to you',
      transfusion:
        'Has your pet received any type of blood product transfusion before (blood or plasma)?',
    },
    donorCreated: 'Donor has been created',
    donorInfoSentToMedicalDirector:
      'The donors info has been sent to medical director!',
    cancel: 'Cancel',
    save: 'Save',
    acceptSimparicaTrio: 'Does Donor Accept Simparica Trio?',
  },
  DonorStatusSelect: {
    comment: 'Comment',
    leaveComment: 'Leave comment',
    enterComment: 'Enter comment',
    selectStatus: 'Please select donor status',
    affiliate: 'Affiliate',
    active: 'Active',
    pending: 'Pending',
    retired: 'Retired',
    trp: 'TRP',
    notAccepted: 'Not Accepted',
    archived: 'Archived',
    requested: 'Requested',
  },
  DonorRetiredReasonSelect: {
    Age: 'Age',
    Health: 'Health',
    Moving: 'Moving',
    Other: 'Other',
    Owner: 'Owner',
    Request: 'Request',
    Temperament: 'Temperament',
    Unresponsive: 'Unresponsive',
    selectReason: 'Select reason',
  },
  EditDonorForm: {
    OptionalSection: {
      distinguishableMarks: 'Distinguishable marks',
      enterDistinguishableMarks: 'Enter distinguishable marks',
      bloodType: 'Blood type',
      selectBloodType: 'Select blood type',
      donorType: 'Donor type',
      selectDonorType: 'Select donor type',
      team: 'Team',
      selectTeam: 'Select team for donor',
      breed: 'Breed',
      enterBreed: "Enter donor's breed",
      color: 'Color',
      enterColor: "Enter donor's color",
      comment: 'Comment',
      labwork: 'Labwork',
      livesSavedRatio: 'LSR (lives saved ratio)',
      lives: 'lives',
      leaveComment: 'Leave comment',
      edit: 'Edit',
      enterDate: 'Enter date',
      distemperDate: 'Administration distemper date',
      retiredDate: 'Retired date',
      rabiesDate: 'Administration rabies date',
      nextLabwork: 'Date of next labwork',
      labworkResults: 'Labwork results',
      cantChooseTeamForDonorWithoutOwner:
        'Donor with no owner cannot be assigned to any owner’s team',
      isDaycareLabel: 'Is donor daycare?',
      isDaycare: 'Is daycare',
      petApprovedDonationML: 'Approved donation amount',
      medicalDirectorApproved: 'Medical director approved draw',
      ml: 'Ml',
      attendDaycareDays: 'Which days will your dog attend daycare on?',
      isRegularlyAttendDaycare:
        'Will not regularly attend daycare. Requires appointment',
      attendDaycareDaysPlaceholder: 'Select days',
      attendDaycareDaysValidationError:
        'Select at least 1 day or select requires appointment option to proceed with daycare location selected',
    },
    AdditionalSection: {
      firstCertificatePrinted:
        'Was the certificate "Gallon Donor Club" printed?',
      secondCertificatePrinted:
        'Was the certificate "2 Gallon Donor Club" was printed?',
    },
    RequiredSection: {
      dateOfBirth: 'Date of birth',
      lastName: 'Last name',
      petName: "Pet's name",
      enterPetName: "Please enter pet's name",
      species: 'Species',
      petAge: "Pet's age",
      enterPetAge: "Please enter pet's age",
      petGender: "Pet's gender",
      petWeight: "Pet's weight",
      enterPetWeight: "Please enter pet's weight",
      neutered: 'Spayed or neutered',
      lb: 'Lb',
      kg: 'Kg',
      years: 'Years',
      pleaseSelectLocation:
        'Please select or enter location which is closer to you',
      medicationsHistory: 'Medications history',
      enterMedicationHistory: "Please enter pet's medication history",
      takeMedications:
        'Does your pet currently take any medications other than heartworm, flea or tick preventatives?',
      transfusion:
        'Has your pet received any type of blood product transfusion before (blood or plasma)?',
      primaryVeterinarian: 'Primary veterinarian',
      enterPrimaryVeterinarian: 'Enter primary veterinarian',
      donationFrequency: 'Approved Donation Frequency (in weeks)',
      acceptSimparicaTrio: 'Does Donor Accept Simparica Trio?',
    },
    DonorStatusSection: {
      comment: 'Comment',
      leaveComment: 'Leave comment',
      enterComment: 'Enter comment',
      status: 'Status',
      microchipNo: 'Microchip #',
      hasMicrochip: 'Has microchip?',
      retirementReason: 'Reason for retirement',
      retiredClarification: 'Retired clarification',
    },
    SaveButton: {
      areYouSureToSave: 'Are you sure to save with warnings?',
      yes: 'Yes',
      no: 'No',
      save: 'Save',
    },
    DeleteButton: {
      areYouSureToDelete:
        'Are you sure you want to permanently delete this donor and all associated information?',
      yes: 'Yes',
      no: 'No',
      delete: 'Delete donor',
      donorDeleted: 'The donor has been successfully deleted.',
      deletingError: 'Error occurred while deleting the donor.',
      donorArchived: 'The donor has been successfully archived.',
      archivedError: 'Error occurred while archiving the donor.',
    },
    ConfirmArchiveModal: {
      donorWontBeDeleted:
        'The donor will not be removed as it contains the following data:',
      sureToArchive: 'Do you want to archive the donor instead of deleting?',
      archive: 'Archive',
      cancel: 'Cancel',
      donations: 'Donations',
      labworks: 'Labworks',
      appointments: 'Appointments',
      donorContOfLabworks: 'Donor has {{count}} labworks',
      donorContOfDonations: 'Donor has {{count}} donations',
      donationId: 'Donation id: ',
    },
    NoteSection: {
      pinnedNotes: 'Pinned notes:',
      send: 'Send',
      notes: 'Notes:',
      smallerThan2mb: 'File must be smaller than {{size}}!',
      mustBeAllowedFormat: 'You can only upload JPG/PNG/PDF file!',
      noteAdded: 'Note added!',
      noteAddingError: 'An error occurred while adding the note',
      noteUpdating: 'Note updated!',
      noteDeleted: 'Note deleted!',
      noteUpdatingError: 'An error occurred while updating the note',
      noteDeletingError: 'An error occurred while deleting the note',
      noteEditedTime: 'Edited at {{time}}',
      close: 'Close',
      save: 'Save',
      edit: 'Edit',
    },
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
  },
  RegisterPetForm: {
    petName: "What is your pet's name?",
    typePetsName: "Type pet's name",
    petBreed: 'What breed is your pet?',
    typeBreed: "Type pet's breed",
    petAge:
      "What is your pet's month and year of birth?(can be stated approximately)",
    typePetsAge: `Type your pet's age (e.g. 2 and 1/2 years type 2)`,
    neutered: 'Is your pet spayed or neutered?',
    petWeight_Dog:
      'How much does your pet weigh? (50lb minimum required to safely donate)',
    petWeight_Cat:
      'How much does your pet weigh? (9.5lb minimum required to safely donate)',
    typePetsWeight:
      "Type your pet's weight (e.g. type 72 if your pet's weight is 72.4 lbs.)",
    hasItTransfusion:
      'Has your pet ever received any type of blood product transfusion before (blood or plasma)?',
    hasItHeartworms: 'Has your pet ever been diagnosed with a heart murmur?',
    doesItTakeMedications:
      'Is your pet currently taking prescription medications other than heartworm, flea, or tick preventative?',
    primaryVeterinarian: 'Who is your primary veterinarian?',
    petSpecies: 'What species is your pet?',
    gender: 'Is your pet a male or female?',
    selectGender: 'Select gender',
    yourAnswer: 'Your answer',
    submit: 'Submit',
    donorHasBeenAdded: 'Donor has been successfully added!',
    medicationHistory: 'Medication History',
    kindOfMedication: 'What medication are they taking?',
    medicationTerm: 'Long Term medication or temporary?',
    enterPetWeight: "Please enter pet's weight",
    pleaseSelectLocation: 'Please select location',
    medicationCommentary: 'Commentary on the medication course',
    lb: 'Lb',
    locationPreferences:
      'Where are you registering to donate? (the name of the location is listed on your registration card)',
    donorWithTransfusionNotAllowed:
      'As your pet has previously been a recipient of blood transfusions, pet is not eligible to donate blood. Thank you again for your interest in our program.',
    donorWithHeartMurmurNotAllowed:
      'As your pet has previously been diagnosed with a heart murmur, pet is not eligible to donate blood. Thank you again for your interest in our program.',
    petWeight:
      'How much does your pet weigh, roughly in pounds? (35lb minimum required to safely donate)',
    transfusion:
      'Has your pet received any type of blood product transfusion before (blood or plasma)?',
    takeMedications:
      'Does your pet currently take any medications other than heartworm, flea or tick preventatives?',

    selectSpecie: 'Select species',
    provideTreatmentInfo:
      'Please provide additional information about course of treatment',
    checkYourEmail: `Check {{email}} email and follow the link`,
    yourEmail: 'What is your email address?',
    enterYourEmail: 'Please type your email',
    yourFirstName: 'What is your first name?',
    enterFirstName: 'Please type your first name',
    yourLastName: 'What is your last name?',
    enterLastName: 'Please type your last name',
    yourPhoneNumber: 'What is your phone number?',
    enterPhoneNumber: 'Please type your phone number',
    yourPreferredContactMethod: 'What is your preferred method of contact?',
    required: '{{field}} is required',
    contactPreferences: 'Contact preferences',
    thisField: 'This field',
    doesPetHaveMicrochip: 'Does pet have microchip?',
    sentEmail: 'We sent you an Email with further instructions',
    attendDaycareDays: 'Which days will your dog attend daycare on?',
    isRegularlyAttendDaycare:
      'Will not regularly attend daycare. Requires appointment',
    attendDaycareDaysPlaceholder: 'Select days',
    attendDaycareDaysValidationError:
      'Select at least 1 day or select requires appointment option to proceed with daycare location selected',
    agreeToNavbb:
      'By checking this box you agree to receive text messages from NAVBB, you can reply stop to opt-out at any time, according to our',
    privacyPolicy: 'Privacy Policy',
  },
  RegisterPetPage: {
    registerPet: 'Register pet',
    k9PetRegistration: 'K9 BLOOD DONOR PET REGISTRATION',
    thanksForSupport: 'Thank you for your support!',
    pleaseFillForm: 'Please fill out 1 form per pet donor.',
  },
  IndividualDonorListPage: {
    pageTitle: 'Individual donor list',
    addNewDonor: 'Add new donor',
    searchDonor: `Search by donor ID, pet's name, last name`,
    uploadCsv: 'Upload CSV',
    all: 'All',
    daycare: 'Daycare',
  },
  AppointmentsDonors: {
    pageDescription:
      'Here displays donors that are cached, retrieved from the nearest appointments for the user, as well as manually cached donors.',
    pageTitle: 'Cached donors',
    on: 'On',
    off: 'Off',
    cacheState: 'Cache state',
    cacheWorking: 'The cache is currently active.',
    cacheNotWorking: 'The cache is currently turned off.',
    cacheEnabled: 'Cache is enabled.',
    cacheDisabled: 'Cache is disabled.',
  },
  IndividualDonorListTable: {
    lastDonationDate: 'Last donation date',
    donorId: 'Donor ID',
    petName: "Pet's name",
    ownerLastName: "Owner's last name",
    bloodType: 'Blood type',
    status: 'Status',
    from0to24daysFilter: '0-24 days since last donation',
    from24to29daysFilter: '25-29 days since last donation',
    moreThan30daysFilter: '30+ days since last donation',
    active: 'Active',
    affiliate: 'Affiliate',
    pending: 'Pending',
    retired: 'Retired',
    requested: 'Requested',
    trp: 'TRP',
    notAccepted: 'Not Accepted',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    archived: 'Archived',
    unknown: 'Unknown',
    type: 'Type',
    individual: 'Individual',
    grouped: 'Grouped',
    location: 'Location',
  },
  DonorDonationsTable: {
    donationId: 'Donation ID',
    date: 'Date',
    location: 'Location',
    comments: 'Comments',
    products: 'Products',
    addComment: 'Add comment',
    productDonation: 'Product donation',
  },
  Groups: {
    pageTitle: 'Group donor list',
    createGroup: 'Create group',
    searchGroup: `Search by owner's or group's name`,
  },
  AddCommentModal: {
    comment: 'Comment',
    enterComment: 'Enter comment',
    save: 'Save',
    addDonationCommentModal: 'Add donation comment modal',
    commentSaved: 'Comment has been saved!',
    cancel: 'Cancel',
    areYouSureSaveEdits: 'Are you sure you want to save edits?',
  },
  GroupsTable: {
    owner: 'Owner',
    groups: 'Groups',
    teams: 'Teams',
    action: 'Action',
    total: 'Total',
    totalDonors: 'Total donors',
  },
  DonorCardInfo: {
    id: 'ID',
    lastName: 'Last name',
    bloodType: 'Blood type',
    status: 'Status',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    donationDate: 'Donation date',
    unknown: 'Unknown',
    active: 'Active',
    affiliate: 'Affiliate',
    pending: 'Pending',
    retired: 'Retired',
    trp: 'TRP',
    notAccepted: 'Not Accepted',
    archived: 'Archived',
    requested: 'Requested',
    location: 'Location',
  },
  IndividualDonorListFilter: {
    filter: 'Filter',
    cancel: 'Cancel',
    status: 'Status',
    bloodType: 'Blood type',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    unknown: 'Unknown',
    active: 'Active',
    affiliate: 'Affiliate',
    pending: 'Pending',
    requested: 'Requested',
    retired: 'Retired',
    trp: 'TRP',
    notAccepted: 'Not Accepted',
    archived: 'Archived',
    from0to24daysFilter: '0-24 days since last donation',
    from24to29daysFilter: '25-29 days since last donation',
    moreThan30daysFilter: '30+ days since last donation',
    lastDonationDate: 'Last donation date',
    type: 'Type',
    individual: 'Individual',
    grouped: 'Grouped',
    locations: 'Locations',
  },
  TeamCollapse: {
    sureToDelete: 'Sure to delete?',
    successfullyUpdated: 'Donor has been removed!',
  },
  GroupCard: {
    groupRemoved: 'Group has been removed!',
    groupUpdated: 'Group has been updated!',
  },
  GroupListActionsButtons: {
    sureYouWantToDeleteThisGroup: 'Are you sure you want to delete this group?',
    successfullyUpdated: 'Data has been successfully updated!',
    successfullyDeleted: 'Group has been successfully deleted!',
  },
  LocationSelect: {
    selectLocation: 'Select location',
  },
  ValidationErrors: {
    petAge: 'Pet age',
    petBirthDate: "Pet's  birth date",
    petWeight: 'Pet weight',
    petGender: 'Pet gender',
    neutered: 'Spayed or neutered',
    acceptSimparicaTrio: 'Does Donor Accept Simparica Trio?',
    location: 'Location',
    medicationHistory: 'Medication history',
    primaryVeterinarian: 'Primary veterinarian',
    ownerLastName: "Owner's last name",
    petName: 'Pet name',
    species: 'Species',
    required: '{{field}} is required!',
    olderThan: 'Donor should be older than {{min}} year',
    youngerThan: 'Donor should not be younger than {{max}} years',
    minWeight: 'Donor should be heavier than {{min}} {{type}}',
    longerThan: '{{field}} should be longer than {{min}} characters',
    shorterThan: '{{field}} should be shorter than {{max}} characters',
    onlyAlphabets: 'It must contain only alphabets',
    mustBeNumber: 'Must be a number',
    mustBeString: 'Must be a string',
    bloodType: 'Blood type',
    veterinarian: 'Veterinarian',
    animalName: 'Animal name',
    collectionDate: 'Collection date',
    mustBeDate: 'Collection date must be a date',
    breed: 'Breed',
    color: 'Color',
    age: 'Age',
    gender: 'Gender',
    patientID: 'Patient ID',
    staffPet: 'Staff pet',
    mustBeInt: 'Must be an integer',
    provideTreatmentInfo:
      'Please provide additional information about course of treatment',
    hasTransfusion: 'Has transfusion',
    takeMedications: 'Take medications',
    mustBeTrimmed: 'The field cannot include leading and trailing spaces',
    team: 'Team',
    group: 'Group',
    owner: 'Owner',
    mustBeEmpty: '{{field}} must be empty',
    allowedSigns: `only {{signs}} signs are allowed`,
    onlyValidSymbols: 'It must contain only alphabets and numbers',
    mustBeTrim: 'The text cannot have leading or trailing spaces',
    distinguishableMarks: 'Distinguishable marks',
    medicationsHistory: 'Medications history',
    noSpacesAtEdgesMessage: `Spaces at the beginning or end not allowed.`,
    atMost: '{{field}} must be at most {{max}} characters',
    comment: 'Comment',
    chipNo: 'Chip number',
    donationFrequency: 'Donation frequency',
    donationFrequencyTitle: 'Please provide a donation frequency.',
    moreThan: '{{field}} must be at least more than {{moreThan}}',
    lessThan: '{{field}} must be at less than {{lessThan}}',
    mustBeInPast: 'The date must be in the past',
  },
  TemperatureChart: {
    date: 'Date',
    temperature: 'Temperature',
  },
  PackedCellVolumeChart: {
    date: 'Date',
    packedCellVolume: 'Packed cell volume',
  },
  PulseChart: {
    date: 'Date',
    pulse: 'Pulse',
  },
  RespirationChart: {
    date: 'Date',
    respiration: 'Respiration',
  },
  TotalSolidsChart: {
    date: 'Date',
    totalSolids: 'Total solids chart',
  },
  WeightChart: {
    date: 'Date',
    weight: 'Weight',
  },
  CapillaryRefillTimeTable: {
    capillaryRefillTime: 'Capillary refill time',
    value: 'Value',
    date: 'Date',
    betweenOneAndTwo: '1-2',
    moreThanTwo: '>2',
    lessThanOne: '<1',
  },
  MucousMembraneTable: {
    mucousMembrane: 'Mucous membrane',
    blue: 'Blue',
    darkPink: 'Dark pink',
    grey: 'Grey',
    lightPink: 'Light pink',
    pigmented: 'Pigmented',
    pink: 'Pink',
    tacky: 'Tacky',
    white: 'White',
    yellow: 'Yellow',
    value: 'Value',
    date: 'Date',
  },
  GroupProfileCards: {
    donorsList: 'List of donors',
    teamsList: 'List of teams',
    group: 'Group',
    changeOwner: 'Change owner',
  },
  GroupProfilePage: {
    pageTitle: 'Group profile',
  },
  GroupProfileDonorsPage: {
    pageTitle: 'Group donors',
  },
  GroupProfileTeamsPage: {
    pageTitle: 'Group teams',
  },
  AttachDonorsModal: {
    attachDonorsToTeamModal: 'Attach donors to a team',
    attachDonors: 'Save',
    donorsHaveBeenAttached: 'Donors have been attached!',
  },
  GroupCardListSorter: {
    groups: 'Groups',
    teams: 'Teams',
    owner: 'Owner',
    donors: 'Donors',
  },
  GroupCardInfo: {
    owner: 'Owner',
    totalDonors: 'Total donors',
  },
  GroupDonorCardListSorter: {
    petName: "Pet's name",
    teams: 'Teams',
  },
  GroupDonorCardList: {
    sureToDelete: 'Sure to delete?',
    successfullyUpdated: 'Data has been successfully updated!',
    successfullyDeleted: 'Data has been successfully deleted!',
    searchDonor: "Search by donor ID, pet's name",
  },
  GroupDonorCardInfo: {
    donorId: 'Donor ID',
    petName: "Pet's name",
    owner: 'Owner',
    bloodType: 'Blood type',
    status: 'Status',
    teams: 'Teams',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    unknown: 'Unknown',
  },
  CreateTeamModal: {
    enterTitle: 'Enter title',
    enterTotal: 'Enter total',
    title: 'Title',
    total: 'Total',
    createTeamModal: 'Create team',
    teamHasBeenCreated: 'Team has been created!',
    noSpacesAtEdgesMessage:
      'Please enter a string without spaces at the beginning or end.',
    create: 'Create',
    required: '{{field}} is required!',
    moreThan: 'It must be more than {{min}} characters',
    lessThan: 'It must be less than {{max}} characters',
  },
  TeamActionsButtons: {
    sureToDelete: 'Sure to delete?',
    successfullyUpdated: 'Data has been successfully updated!',
    successfullyDeleted: 'Team has been successfully deleted!',
  },
  CreateGroupModal: {
    groupHasBeenCreated: 'Group has been created!',
    createGroup: 'Create Group',
    create: 'Create',
    totalTeams: 'Total teams',
    enterTotalTeams: 'Enter total teams',
    nameOfGroup: 'Name of group',
    enterGroupName: 'Enter a name of group',
    nameOfTeam: 'Name of a team',
    enterTeamName: 'Enter a name of a team',
    owner: 'Owner',
    enterOwnerName: "Enter owner's name",
    nameOfGroupRequired: 'Name of group is a required field',
    totalTeamsRequired: 'Total number of teams is a required field',
    nameOfTeamRequired: 'Name of team is a required field',
    totalDonorsRequired: 'Total number of donors in team is a required field',
    ownerRequired: 'Owner is a required field',
    mustBeNumber: 'Must be a number',
    mustBeString: 'Must be a string',
    totalDonors: 'Number of donors in a team',
    mustBeTrimmed: 'The field cannot include leading and trailing spaces',
    onlyValidSymbols: 'It must contain only alphabets and numbers',
    lessThan: 'It must be less than {{max}} characters',
    enterTotalDonors: 'Enter number of donors in a team',
  },
  DonorsTable: {
    donorId: 'Donor ID',
    petName: "Pet's name",
    ownersLastName: "Owner's last name",
    bloodType: 'Blood type',
    status: 'Status',
    teams: 'Teams',
    action: 'Action',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    unknown: 'Unknown',
    affiliate: 'Affiliate',
    active: 'Active',
    pending: 'Pending',
    requested: 'Requested',
    retired: 'Retired',
    trp: 'TRP',
    notaccepted: 'Not accepted',
    archived: 'Archived',
    successfullyUpdated: 'Donor has been added to a team!',
    successfullyDeleted: 'Donor has been successfully deleted!',
    areYouSureToDelete: 'Are you sure you want to delete?',
    attach: 'Attach',
    searchDonor: "Search by donor ID, pet's name",
  },
  TeamsTable: {
    successfullyUpdated: 'Data has been successfully updated!',
  },
  ReserveDonors: {
    pageTitle: 'Training Reserve donors',
  },
  ReserveDonorsTable: {
    donorId: 'Donor ID',
    petName: "Pet's name",
    ownerLastName: "Owner's last name",
    bloodType: 'Blood type',
    enteredDate: 'Entered Date',
    daysInReserve: 'Days in reserve',
    notes: 'Notes',
    dea1positive: 'DEA 1 Positive',
    dea1negative: 'DEA 1 Negative',
    unknown: 'Unknown',
    searchDonor: 'Search by donor ID, donor name, last name',
    addInfo: 'Add info',
    deleteNoteConfirm: 'Are you sure you want to delete the note?',
    noteWasDeleted: 'The note has been successfully deleted.',
    noteWasntDeleted: 'The note could not be deleted.',
  },
  AddDonorNoteModal: {
    enterNote: 'Enter note',
    noteSaved: 'Note has been saved',
    required: '{{field}} is required!',
    text: 'Text',
    cancel: 'Cancel',
    save: 'Save',
    addDonorNoteModal: 'Add donor note modal',
    noSpacesAtEdgesMessage:
      'Please enter a string without spaces at the beginning or end.',
    areYouSureSaveEdits: 'Are you sure to save notes?',
    noteUpdated: 'Note has been updated',
    atMost: 'Text must be at most {{max}} characters',
  },
  RoutineTestForm: {
    veterinarian: 'Veterinarian',
    ownerLastName: "Owner's last name",
    animalName: 'Animal name',
    collectionDate: 'Collection date',
    species: 'Species',
    breed: 'Breed',
    age: 'Age',
    gender: 'Gender',
    patientID: 'Patient ID/MRN',
    staffPet: 'Staff pet',
    enterVeterinarian: 'Enter veterinarian',
    enterOwnerLastName: 'Enter owner last name',
    enterAnimalName: 'Enter animal name',
    enterBreed: 'Enter breed',
    enterAge: 'Enter age',
    enterPatientId: 'Enter patient ID/MRN',
    staffPetExplanation:
      'Please check if this is for an employee’s personal pet',
    createAndPrint: 'Create and print',
    labworkHasBeenSuccessfullyCreated: 'Labwork has been successfully created!',
    enterNotes: 'Enter notes',
    notes: 'Notes',
    dvmexam: 'DVM Exam',
  },
  RoutineTestModal: {
    title: 'Routine test',
    close: 'Close',
  },
  LabworkTable: {
    date: 'Date',
    status: 'Status',
    result: 'Result',
    dueDate: 'Due date',
    labworkHasBeenSuccessfullyUpdated: 'Labwork has been successfully updated!',
    emailHasBeenSent: 'Email has been sent!',
    clickToUpload: 'Click to Upload',
    routineTest: 'Routine test',
    donorId: 'Donor ID',
    name: 'Name',
    lastName: 'Last name',
    actions: 'Actions',
    ActionsCell: {
      attachments: 'Attachments',
      deleteLabwork: 'Delete labwork',
      printLabwork: 'Print labwork',
    },
  },
  GeneralLabworkTable: {
    donorId: 'Donor ID',
    petName: "Pet's name",
    lastName: "Owner's last name",
    dateOfLatestLabwork: 'Date of latest labwork',
    status: 'Status',
    annualdue: 'Annual Due',
    awaitingdvmapproval: 'Awaiting DVM Approval',
    cleared: 'Cleared',
    collected: 'Collected',
    pendingresult: 'Pending result',
    recheck: 'Re-check',
    sent: 'Sent',
    searchLabwork: 'Search for donor ID, last name',
    link: 'Link',
    failedcbc: 'Failed CBC',
    failedchem: 'Failed Chem',
    failedhealthconcern: 'Failed Health Concern',
    failedinfectious: 'Failed Infectious',
    failedtemperament: 'Failed Temperament',
  },
  RoutineTest: {
    AdditionalTestsSection: {
      title: 'NAVBB Panel',
    },
    BundleSection: {
      title: 'Easy Bundle and Custom Panels',
      explanationFirstRow:
        'If ordering a preset bundle, please specify the bundle you are ordering and follow instructions below for',
      explanationSecondRow:
        'each test within the bundle. Or create a custom panel by specifying tests of your choice.',
      tabTitle1: 'Junior Wellness',
      tabSubTitle1: 'Chem 18, CBC, UA',
      tabTitle2: 'Adult Wellness',
      tabSubTitle2: 'Chem 25, CBC, UA',
      tabTitle3: 'Senior Basic',
      tabSubTitle3: 'Chem 25, CBC, T4',
      tabTitle4: 'Senior Wellness',
      tabSubTitle4: 'Chem 25, CBC, T4, UA',
      caption: 'Upgrade to Comprehensive CBC',
    },
    ChemistrySection: {
      chemistry: 'CHEMISTRY',
      chem18: 'Chem 18',
      chem18Subtitle: '0.5 mL serum STT or RTT',
      chem25: 'Chem 25',
      chem25Subtitle: '1 mL serum STT or RTT',
    },
    ClinicSection: {
      clinicAccount: 'Clinic Account #',
      zipCode: 'Zip code',
      hospitalOrClinic: 'Hospital/Clinic',
    },
    ContactsSection: {
      veterinarian: 'Veterinarian',
      ownerLastName: 'Owner Last Name',
      animalName: 'Animal Name',
      collectionDate: 'Collection Date',
      species: 'Species',
      breed: 'Breed',
      age: 'Age',
      gender: 'Gender',
      patientIdOrMrn: 'Patient ID/MRN',
      staffPet: 'Staff Pet',
      staffPetExplanation:
        'Please check if this is for an employee’s personal pet',
    },
    CopyInfoSection: {
      whiteCopy: 'White copy',
      lab: 'Lab',
      yellowCopy: 'Yellow copy',
      customer: 'Customer',
    },
    CopyrightSection: {
      firstRow:
        'All trademarks are the property of Zoetis Services LLC or a related company or a licensor unless otherwise noted.',
      secondRow: '© 2020 Zoetis Services LLC. All rights reserved. ZRL-00006A',
    },
    DetailsSection: {
      text: 'For details about each test (e.g., methodology, turnaround time), see Directory of Services at zoetisreflabs.com.',
    },
    EndocrinologySection: {
      endocrinology: 'ENDOCRINOLOGY',
      totalT4: 'Total T4',
      totalT4Subtitle: '0.5 mL serum in SST or RTT',
      freeT4: 'Free T4',
      freeT4Subtitle: '0.5 mL serum in SST or RTT',
      tsh: 'TSH',
      tshSubtitle: '0.5 mL serum in SST or RTT',
      cortisol: 'Cortisol',
      cortisolSubtitle: '0.5 mL serum in RTT',
      fructosamine: 'Fructosamine',
      fructosamineSubtitle: '0.5 mL serum in SST or RTT',
    },
    FecalSection: {
      fecal: 'FECAL',
      ovaParasite: 'Ova and Parasite',
      ovaParasiteSubtitle: '3 gm fresh feces',
      giardia: 'Giardia ELISA',
      giardiaSubtitle: '3 gm fresh feces',
    },
    Head: {
      routineTests: 'Routine Tests',
      orderForm: 'Order form',
    },
    HematologySection: {
      hematology: 'HEMATOLOGY',
      cbc: 'CBC',
      cbcSubtitle: '2 mL LTT, air dried slides',
      comprehensiveCbc: 'Comprehensive CBC',
      comprehensiveCbcSubtitle: '2 mL LTT, air dried slides',
    },
    IndividualTestsSection: {
      individualTests: 'INDIVIDUAL TESTS',
      dggrLipase: 'DGGR Lipase',
      dggrLipaseSubtitle: '0.5 mL serum in SST or RTT',
      sdma: 'SDMA',
      sdmaSubtitle: '1 mL serum in SST or RTT',
      triglycerides: 'Triglycerides',
      triglyceridesSubtitle: '0.5 mL serum in SST or RTT',
    },
    LaboratoryUseOnlyTable: {
      sst: 'SST',
      btt: 'BTT',
      urine: 'Urine',
      histo: 'Histo',
      rtt: 'RTT',
      gtt: 'GTT',
      swab: 'Swab',
      cyto: 'Cyto',
      ltt: 'LTT',
      ctt: 'CTT',
      media: 'Media',
      other: 'Other',
      grnTT: 'GrnTT',
      ptt: 'PTT',
      slide: 'Slide',
      fecal: 'Fecal',
      onCells: 'On cells',
      crit: 'Crit',
      frozen: 'Frozen',
      history: 'History',
      totalPg: 'Total Pg',
    },
    Marks: {
      sst: 'SST',
      sstMeaning: 'Serum Separator',
      rtt: 'RTT',
      rttMeaning: 'Serum (no gel)',
      ltt: 'LTT',
      lttMeaning: 'EDTA',
      btt: 'BTT',
      bttMeaning: 'Sodium citrate',
      grntt: 'GRNTT',
      grnttMeaning: 'Heparin',
      ctt: 'CTT',
      cttMeaning: 'Sterile no additive tube',
    },
    QuestionsSection: {
      text: 'For any questions or to order, please call 1-888-965-9652 or visit zoetisreflabs.com to see our full directory of tests.',
    },
    SerologySection: {
      stronglyOrInfectionDiseases: 'Serology/infectious diseases',
      tickPanel: 'Tick Panel',
      tickPanelDiseases: 'Ehrlichia, Anaplasma, Borrelia (Lyme)',
      tickPanelDetails: '0.25 mL of serum, plasma, or whole blood',
      heartwormAntigen: 'Heartworm Antigen',
      heartwormAntigenDetails: '0.25 mL of serum, plasma, or whole blood',
      felvOrFiv: 'FeLV/FIV',
      felvOrFivDetails: '0.25 mL of serum, plasma, or whole blood',
    },
    UrinalysisSection: {
      title: 'Urinalysis',
      itemTitle1: 'Urinalysis',
      itemSubTitle1: '2 mL urine in sterile container',
      itemSubTitleAdditionalInfo1: 'Collection: __Cysto __Cath __Free',
      itemTitle2: 'Urine Protein: Creatinine Ratio',
      itemSubTitle2: '1 mL urine in sterile container',
    },
  },
  DonorLabwork: {
    routineTest: 'Routine test',
    labworkHasBeenSuccessfullyUpdated: 'Labwork has been successfully updated!',
    emailHasBeenSent: 'Email has been sent!',
    labworkHasBeenDeleted: 'Labwork has been deleted!',
  },
  PrintLabworkModal: {
    print: 'Print',
    title: 'Print labwork',
  },
  PrintLabworkForm: {
    formToPrint: 'Form to print',
    selectPlaceholder: 'Select form to print',
    routineTest: 'Routine Test',
    athensVeterinaryDiagnostic: 'Athens Veterinary Diagnostic',
    stateLabSubmissionForm: 'State Lab Submission Form',
    generalSubmissionForm: 'Cornell Iron Panel',
    IDEXXSubmissionForm: 'IDEXX Submission Form',
  },
  LabworkCardListSorter: {
    collectionDate: 'Collection date',
  },
  LabworkCardInfo: {
    date: 'Date',
    status: 'Status',
  },
  DonorDonationCardInfo: {
    donationId: 'Donation ID',
    location: 'Location',
    comments: 'Comments',
    products: 'Products',
  },
  DonorDonationCardListSorter: {
    date: 'Date',
  },
  UpdateVitalsParametersModal: {
    vitalsParametersUpdated: 'Vitals parameters has been updated successfully!',
    updateVitalsParameters: 'Update vitals parameters',
    areYouSureSaveEdits: 'Are you sure to save edits?',
    cancel: 'Cancel',
    save: 'Save',
    enterTemperatureToCheckMin:
      'Enter min temperature for checking by another person',
    enterTemperatureToCheckMax:
      'Enter max temperature for checking by another person',
    enterTemperatureToStopMin: 'Enter min temperature to stop process',
    enterTemperatureToStopMax: 'Enter max temperature to stop process',
    enterPulseMin: 'Enter min pulse',
    enterPulseMax: 'Enter max pulse',
    enterRespirationMin: 'Enter min respiration',
    enterRespirationCriticalValues: 'Enter respiration critical values',
    enterPackedCellVolumeMax: 'Enter max packed cell volume',
    enterPackedCellVolumeMin: 'Enter min packed cell volume',
    enterTotalSolidsMax: 'Enter max total solids',
    enterTotalSolidsMin: 'Enter min total solids',
    enterMucousMembraneCriticalValues: 'Enter mucous membrane critical values',
    enterCapillaryRefillTimeMax: 'Enter max capillary refill time',
    enterCapillaryRefillTimeMin: 'Enter min capillary refill time',
    enterWeightPercentageDifference: 'Enter weight percentage difference',
    enterWeightMin: 'Enter min weight ',
    weightPercentageDifference: 'Weight percentage difference',
    weightMin: 'Min weight',
    capillaryRefillTimeMin: 'Min capillary refill time',
    capillaryRefillTimeMax: 'Max capillary refill time',
    mucousMembraneCriticalValues: 'Mucous membrane critical values',
    totalSolidsMin: 'Min total solids',
    totalSolidsMax: 'Max total solids',
    packedCellVolumeMin: 'Min packed cell volume',
    packedCellVolumeMax: 'Max packed cell volume',
    respirationCriticalValues: 'Respiration critical values',
    respirationMin: 'Min respiration',
    pulseMax: 'Max pulse',
    pulseMin: 'Min pulse',
    temperatureToStopMax: 'Max temperature to stop process',
    temperatureToStopMin: 'Min temperature to stop process',
    temperatureToCheckMax: 'Max temperature to check by another person',
    temperatureToCheckMin: 'Min temperature to check by another person',
    required: 'This field is required!',
    moreThan: 'It must be more than {{min}} characters',
    close: 'Close',
  },
  CreateDonationAlerts: {
    temperatureCheckMessage: 'Temperature check',
    temperatureCheckDescription:
      ' Please, check the rectal temperature and call the medical director immediately',
    temperatureStopMessage: 'Temperature value is critical',
    temperatureStopDescription: 'Immediately stop the process',
    heartRateMessage: 'Heart rate check',
    heartRateDescription:
      'Please, ask another person to check the heart rate too and call the medical director immediately',
    pcvMessage: 'Packed cell volume check',
    pcvDescription: 'Pay attention, donor is ineligible to donate',
    pcvPrevDescription:
      'Please contact medical director to discuss 10+ PCV drop',
    tsMessage: 'Total solids values are critical',
    tsDescription: 'Please, call medical director immediately',
    weightMessage: 'Weight value is critical',
    weightDescription: 'Please, call medical director immediately',
    overdueLabworkMessage: 'Overdue labwork',
    overdueLabworkDescription:
      'The last labwork was collected more than 350 days ago.',
    noLabworkMessage: 'No labwork',
    noLabworkDescription: "Donor haven'had created labwork yet",
  },
  DonorPictures: {
    addPicture: 'Add picture',
    changePicture: 'Change picture',
    deletePicture: 'Delete picture',
  },
  CollectedMlsChart: {
    collectedMls: 'Collected mls',
    date: 'Date',
    totalCollectedMls: 'Total collected mls',
  },
  UploadDonorPhoto: {
    mustBeLessThan: 'Image must be smaller than {{mb}}MB!',
  },
  AddDonorToCacheForm: {
    cache: 'Cache',
    donorSavedToCache: 'The donor has been successfully added to the cache!',
    donorRequired: 'Donor is required field',
    alreadyInCache: 'Donor has been already added to the cache!',
  },
  UploadCSVDonorsButton: {
    uploadCsv: 'Upload CSV',
    fileUploaded: '{{fileName}} file uploaded successfully.',
    uploadingFailed: '{{fileName}} file upload failed.',
  },
  CsvDonorsUploadingModal: {
    uploadDonors: 'Upload donors',
    donorsCreated: 'Donors has been created successfully.',
    creationFailed: 'Donors creation failed.',
    dataIsNotValid: 'Oops, some data is not valid! Check error messages',
    dataIsValid: 'Data is valid!',
    selectOwner: 'Select owner',
    fileIsEmpty: 'File is empty or not selected',
    donorsAreNotSameType: 'Donors are not same type',
    Footer: {
      upload: 'Upload',
      cancel: 'Cancel',
    },
    donorsTypeWillBeChanged:
      'The donors type will be changed to the owner type',
  },
  LabworkStatusSelect: {
    annualDue: 'Annual Due',
    awaitingDvmApproval: 'Awaiting DVM approval',
    cleared: 'Cleared',
    collected: 'Collected',
    pendingResult: 'Pending result',
    reCheck: 'Recheck',
    sent: 'Sent',
    failedCBC: 'Failed CBC',
    failedChem: 'Failed Chem',
    failedHealthConcern: 'Failed Health Concern',
    failedInfectious: 'Failed Infectious',
    failedTemperament: 'Failed Temperament',
  },
  GroupSelect: {
    selectGroup: 'Select or search group',
  },
  TeamSelect: {
    selectTeam: 'Select team',
  },
  DonorSelect: {
    selectOrSearchDonor: "Select or search donor by pet's name",
  },
  UpdateDonorsOwnerModal: {
    selectNewOwner: 'Select new owner',
    updateOwner: 'Update owner',
    areYouSureSaveEdits: 'Are you sure to update owner?',
    cancel: 'Cancel',
    save: 'Save',
    ownerChanged: 'Owner has been successfully changed',
    drop: 'Drop',
    areYouSureDropOwner: 'Are you sure to drop owner?',
    selectOrSearchOwner: 'Select or search owner',
    ownerDropped: 'Owner has been successfully removed',
  },
  UpdateGroupOwnerModal: {
    selectNewOwner: 'Select new owner',
    updateOwner: 'Update owner',
    areYouSureSaveEdits: 'Are you sure to update owner?',
    cancel: 'Cancel',
    save: 'Save',
    ownerChanged: 'Owner has been successfully changed',
    selectOrSearchOwner: 'Select or search owner',
  },
  IndividualDonorListSort: {
    name: 'Name',
    lastName: 'Last name',
    sort: 'Sort',
    cancel: 'Cancel',
  },
  DonorSpeciesRadioGroup: {
    dog: 'Dog',
    cat: 'Cat',
  },
  DonorGenderRadioGroup: {
    male: 'Male',
    female: 'Female',
  },
  LabworkAttachmentsTable: {
    clickToUpload: 'Click to upload',
    labworkHasBeenDeleted: 'Labwork has been deleted',
    emailHasBeenSent: 'Email has been sent',
    file: 'File',
    share: 'Share',
    deleteFile: 'Delete file',
    sureToDeleteAttachment: 'Are you sure to delete attachment?',
    fileUploaded: 'File has been uploaded!',
    fileMustBeLessThan: 'The file must be less than {{size}}MB!',
    uploadError: 'The file could not be uploaded.',
  },
  LabworkAttachmentsModal: {
    labworkAttachments: 'Labwork attachments',
  },
  FormsToPrint: {
    submittingVeterinarian: 'Submitting Veterinarian *',
    clinicName: 'Clinic Name',
    address: 'Address',
    cityStateZip: 'City, State, Zip',
    phoneNumber: 'Phone No.',
    faxNumber: 'Fax No.',
    emailAddress: 'E-mail Address:',
    submittingVetSignature: "Submitting Vet's Signature:",
    owner: 'Owner',
    country: 'Country',
    town: 'Town',
    NYSPremisesID: 'NYS Premises ID',
    regulatory: 'Regulatory',
    export: 'Export',
    checkAppropriate: 'Check if appropriate:',
    destinationCountry: 'Country of Destination',
    shipperExporter: 'Shipper/Exporter',
    generalLaboratorySubmissionForm: 'General Laboratory Submission Form',
    veterinarian: 'Veterinarian:',
    clinic: 'Clinic:',
    addressLabel: 'Address:',
    cityStateZipLabel: 'City, State, Zip:',
    phone: 'Phone:',
    fax: 'Fax:',
    email: 'E-Mail:',
    preferredReportDistributionMethod: 'Preferred Report Distribution Method:',
    ownerSubmitter: 'Owner/Submitter:',
    name: 'Name:',
    billTo: 'Bill to:',
    vetPractice: 'Vet Practice',
    ownerOption: 'Owner',
    other: 'Other',
    purposeOfTest: 'Purpose of Test:',
    diagnostic: 'Diagnostic',
    surveillance: 'Surveillance',
    flockHerdInformation: 'Flock/Herd Information:',
    numberInGroup: '# in Group:',
    numberAffected: '# Affected:',
    numberDead: '# Dead:',
    checkOne: 'Check one:',
    reportTo: 'Report to:',
    animalID: 'Animal’s ID',
    date: 'Date',
    county: 'County',
    specimen: 'Specimen',
    species: 'Species',
    breed: 'Breed',
    sex: 'Sex',
    age: 'Age',
    pickUp: 'Pick up',
    enterCornelAHDC: 'Enter Your Cornell AHDC Acct. No.',
    inputInternalCaseNumber: 'Your Internal Case / Reference No. **',
    healthScreening: 'Health Screening',
    GeneralSubmissionFormTitle:
      'PLEASE COMPLETE ALL FIELDS, PRINT LEGIBLY, AND ENTER ONLY ONE OWNER PER FORM',
    IDEXXSubmissionForm: {
      forLabs: 'FOR LABS USE ONLY',
      collectionDate: 'COLLECTION DATE',
      head: 'Customer Support',
      number: '1-888-433-9987',
      canine: 'CANINE TESTS',
      feline: 'FELINE TESTS',
      fecals: 'FECALS',
      hematology: 'HEMATOLOGY',
      profilesTitle: 'CANINE/FELINE PROFILES',
      profilesSubtitle: 'All profiles require serum and lavender-top tube',
      endocrinology: 'ENDOCRINOLOGY',
      chemistry: 'CHEMISTRY PANELS',
      therapeutic: 'THERAPEUTIC DRUG MONITORING',
      urine: 'URINE/STONE ANALYSIS',
      serology: 'SEROLOGY',
      footerText: 'WHITE COPY—LABORATORY • YELLOW COPY—HOSPITAL • REV 5-2020',
      footerNumber: '09-71756-09',
    },
    GeneralSubmissionForm: {
      general: 'General',
      footerResponsibleNote:
        '*The submitting veterinarian is responsible for the requested tests, fees associated with this submission, and to notify the owner of test results.',
      footerInternalNote:
        '**If your Internal Reference No. is entered on this form, it will be used to identify this case on the test result form and on the billing statement (max. 17 character field)',
      footerPageOf: 'Page ____ of ____',
      footerOrgCode: 'ORG-WEB-027-V01',
      Head: {
        formTitle: 'General Submission Form',
        animalHealthDiagnosticCenter: 'Animal Health Diagnostic Center',
        collegeOfVeterinaryMedicineCornellUniversity:
          'College of Veterinary Medicine, Cornell University',
        inPartnershipWith: 'In Partnership with the NYS Dept. of Ag & Markets',
        USPostalServiceAddress: 'US Postal Service Address',
        boxAddress: 'PO Box 5786',
        boxCity: 'Ithaca, NY 14852-5786',
        fedExService: 'FedEx/UPS Service',
        fedAddress: 'Address: 240 Farrier Rd.',
        fedCity: 'Ithaca, NY 14853',
        AHDCContacts: 'AHDC Contacts',
        phone: 'Phone:',
        fax: 'Fax:',
        email: 'E-mail:',
        web: 'Web:',
        labUseOnly: 'LAB USE ONLY',
        accessionNumberAndDate: 'AHDC Accession No. / Date',
        note: 'PLEASE NOTE: SAMPLES SUBMITTED FOR TESTING BECOME THE PROPERTY OF THE ANIMAL HEALTH DIAGNOSTIC CENTER AND MAY BE TESTED AS PART OF STATE/FEDERAL SURVEILLANCE PROGRAMS',
      },
      ContactsForm: {
        title:
          'PLEASE COMPLETE ALL FIELDS, PRINT LEGIBLY, AND ENTER ONLY ONE OWNER PER FORM',
      },
      History: {
        title: 'Additional Info / History:',
        historyClinicalInfo: 'HISTORY/CLINICAL INFORMATION:',
        checkAndApply: 'Please check all that apply:',
        hasRelatedMaterialBeenSubmitted:
          'Has related material been submitted previously for this',
        animalHerd: 'animal(s)/herd',
        healthScreening: 'Health Screening',
        y: 'Y',
        n: 'N',
        accession: 'Accession No.',
        dateOfOnsetIllness: 'Date of onset of Herd illness:',
        inAnimalsSubmitted: 'In animals submitted:',
        herdSize: 'Herd size:',
        deadNo: 'No. dead:',
        affected: 'No. affected:',
        checkHistory:
          "Check here if history is continued on back of this page, or if add'l history is attached",
        normal: 'Normal',
        hematologicalHemorrhage: 'Hematological/Hemorrhage',
        dermatological: 'Dermatological',
        fever: 'Fever',
        neurological: 'Neurological',
        hepatic: 'Hepatic',
        gastrointestinalDiarrhea: 'Gastrointestinal/Diarrhea',
        abortionReproFailure: 'Abortion/Repro Failure',
        endocrine: 'Endocrine',
        suddenDeath: 'Sudden Death',
        urinaryUrogenital: 'Urinary/Urogenital',
        musculoskeletalLameness: 'Musculoskeletal/Lameness',
        edema: 'Edema',
        ocular: 'Ocular',
        neoplasia: 'Neoplasia',
        chronicWeightLoss: 'Chronic Weight Loss',
        productionPerformanceDecline: 'Production/Performance decline',
        respiratory: 'Respiratory',
        anorexia: 'Anorexia',
        cardiac: 'Cardiac',
        erosionVesicular: 'Erosion/Vesicular',
        other: 'Other',
      },
      AnimalIdentification: {
        fedex: 'FEDEX',
        mail: 'MAIL',
        fedexGrnd: 'FEDEX-GRND',
        priMail: 'PRI MAIL',
        upsGrnd: 'UPS GRND',
        expMail: 'EXP MAIL',
        upsNd: 'UPS-ND',
        frozen: 'FROZEN',
        dryIce: 'DRY ICE',
        rmTemp: 'RM TEMP',
        coldPack: 'COLD PACK',
        cool: 'COOL',
        none: 'NONE',
        cold: 'COLD',
        other: 'OTHER',
        comment: 'COMMENT',
        AnimalIdentification: 'ANIMAL IDENTIFICATION',
        indicate: 'INDICATE ',
        specimenType: 'SPECIMEN TYPE',
        anatomicLocation: '(AND ANATOMIC LOCATION - if appropriate)',
        dateTaken: 'DATE TAKEN',
        testRequested: 'TEST(S) REQUESTED',
        perAnimal: '(per animal)',
        enterFullName: 'ENTER FULL NAME OF TEST',
        codes:
          'SEX CODES: M=Male, MR=Mare (equine only), MC=Castrated Male, F=Female, SF=Spayed Female AGE CODES: Y=Years, M=Months, W=Weeks, D=Days; DOB=Date of Birth',
        number: 'NO.',
        nameOrIdNum: 'NAME / IDENTIFIER NO.',
        species: 'SPECIES',
        breed: 'BREED',
        sex: 'SEX',
        ageOrDob: 'AGE / DOB',
        serum: 'Serum',
        comments: 'Comments:',
        checkIfcontinuationPageIncluded: 'check if continuation page included',
        AHDCUseOnly: 'AHDC USE ONLY',
        openedBy: 'OPENED BY:',
      },
    },
    StateLabSubmissionForm: {
      footerText:
        'D40401 Service Request Form; Version 5; Effective Date 07/01/2022; Associated Document: P402; Issuing Authority Program Manager; Page 1 of 2 Samples submitted for testing become the property of VDACS and may be tested as part of State/Federal Surveillance Program',
      Head: {
        title:
          'Virginia Department of Agriculture and Consumer Services Regional Animal Health Laboratory System',
        harrisonburgAddress:
          'Harrisonburg RAHL \n261 Mt. Clinton Pike \nHarrisonburg, VA 22802 \n540-209-9130',
        lynchburgAddress:
          'Lynchburg RAHL \n4832 Tyreeanna Road \nLynchburg, VA 24504 \n434-200-9988',
        warrentonAddress:
          'Warrenton RAHL \n272 Academy Hill Road \nWarrenton, VA 20186 \n540-316-6543',
        wythevilleAddress:
          'Wytheville RAHL \n250 Cassell Road \nWytheville, VA 24382 \n276-228-5501',
        richmondOffice: 'Richmond Office \n804-786-9202',
        accession: 'Accession #:',
        receipt: 'Receipt#',
        amountOfPay: 'Amount Paid $',
        labUseOnly: 'Lab Use Only',
      },
      AnimalIdentification: {
        animalIdentificationTitle: 'Animal Identification:',
        additionalSpaceNote: '(Additional space on cont. sheet)',
        dateCollected: 'Date Collected',
        dateShipped: 'Date Shipped (Client Use):',
        no: 'No.',
        officialAnimalID: 'Official Animal ID/Name',
        species: 'Species',
        breed: 'Breed',
        sex: 'Sex',
        age: 'Age',
        specimenType: 'Specimen Type*',
        testRequested: 'Test Requested',
        serum: 'Serum',
        IFA: 'Brucella IFA',
      },
      History: {
        historyTitle: 'History',
        clinicalSignsNote:
          '(clinical signs, vaccination, treatment, nutrition, etc.):',
        durationOfIllness: 'Duration of Illness:',
        dateOfDeath: 'Date of Death:',
        euthanized: 'Euthanized?',
        method: 'Method:',
        fixedTissueNote:
          '*If fixed tissue for Histopathology- please indicate exact location:',
        ruleOuts: 'Rule Outs:',
        yes: 'Yes',
        no: 'No',
      },
      Receipt: {
        labUseOnly: '(Lab Use Only)',
        methodOfReceipt: 'Method of Receipt:',
        dropOff: 'Drop Off',
        mailIn: 'Mail-In',
        other: 'Other',
        conditionUponReceipt: 'Condition upon Receipt:',
        acceptable: 'Acceptable',
        unacceptable: 'Unacceptable',
        ifUnacceptableReason: 'If unacceptable, reason:',
      },
    },
    AthensVeterinaryDiagnostic: {
      pleaseFillOutForm:
        'PLEASE FILL OUT THIS FORM COMPLETELY INCLUDING HISTORY ON REVERSE SIDE: By submitting diagnostic specimens to the AVDL, clients are considered to have agreed to AVDL testing procedures and policies, including billing. If tests not offered or temporarily out of service at AVDL are requested, specimens will be referred to other reputable laboratories and a shipping fee/test charges from the referral laboratory will be added to the client’s bill.',
      checkAppropriateBoxes:
        'Check the appropriate box(es) for requested test. *Call laboratory or consult user manual for appropriate specimens.',
      cultureAndIdentification: 'CULTURE & IDENTIFICATION',
      formNumber: 'Form: Acc010.14',
      revisionDate: 'Rev. 9/21/2017',
      Head: {
        laboratoryName: 'Athens Veterinary Diagnostic Laboratory',
        collegeName: 'College of Veterinary Medicine',
        universityName: 'University of Georgia',
        address: '501 D.W. Brooks Dr. Athens, Georgia 30602-7383',
        contactInfo: 'Tel: (706) 542-5568; Fax: (706) 583-0654',
        website: 'Website:',
        websiteUrl: 'www.vet.uga.edu/dlab',
        licenseNumberRequired: 'Veterinary License Number Required',
      },
      columnBuilderConfig: {
        bacteriologyMycology: 'BACTERIOLOGY / MYCOLOGY',
        virology: 'VIROLOGY',
        clinicalPathology: 'CLINICAL PATHOLOGY',
        pathology: 'PATHOLOGY',
        completeHistory: '(Complete history on reverse side)',
        antibodyAntigenMolecularDetection:
          'ANTIBODY, ANTIGEN & MOLECULAR DETECTION',
        checkAppropriateBox:
          'Check the appropriate box against each disease agent: SER: serology (= antibody detection) FA: fluorescent antibody staining (= antigen detection) PCR: polymerase chain reaction (= nucleic acid detection)',
        bovine: 'BOVINE',
        equineViralArteritis: 'Equine Viral arteritis',
        prescottiaEqui: 'Prescottia equi (Rhodococcus equi) spp.',
        streptococcusEqui: 'Streptococcus equi spp.',
        canine: 'CANINE',
        equine: 'EQUINE',
        feline: 'FELINE',
        porcine: 'PORCINE',
        miscellaneous: 'MISCELLANEOUS',
        specialProcedures: 'SPECIAL PROCEDURES',
        parasitology: 'PARASITOLOGY',
        aerobicCulture: 'Aerobic culture & Sensitivity',
        anaerobicCulture: 'Anaerobic culture',
        fungalYeastCulture: 'Fungal/Yeast culture',
        minimumAntimicrobialInhibitoryConcentration:
          'Minimum Antimicrobial Inhibitory Concentration (MIC)',
        salmonellaCulture: 'Salmonella spp. culture',
        mycobacteriumCulture: 'Mycobacterium spp. culture',
        mycoplasmaCulture: 'Mycoplasma spp. culture',
        stains: 'Stains (Please select)',
        acidFast: '*acid fast',
        gram: '*gram',
        other: 'Other',
        virusIsolation: 'Virus Isolation (Specify Virus)',
        electronMicroscopy: 'Electron Microscopy',
        rabies: 'Rabies',
        humanExposure: 'Human Exposure',
        animalExposure: 'Animal Exposure',
        noExposure: 'No Exposure',
        cbc: 'CBC (EDTA, 2 air-dried slides)',
        chemistryProfile: 'Chemistry profile (1 ml serum)',
        urinalysis: 'Urinalysis- Collection Method',
        amylase: 'Amylase (serum)',
        bileAcid: 'Bile acid (serum)',
        bloodParasites: 'Blood parasites (2 air-dried smears)',
        cortisol: 'Cortisol',
        lipase: 'Lipase (serum)',
        t4: 'T4 (canine, feline, equine; 0.5 ml serum)',
        tsh: 'TSH (canine, feline, equine; 0.5 ml serum)',
        urineProteinCreatinineRatio: 'Urine protein.creatinine ratio',
        biopsy: 'Biopsy',
        extended: 'Extended',
        routine: 'Routine',
        cytology: 'Cytology',
        dermatopathology: 'Dermatopathology',
        necropsy: 'Necropsy',
        mailInNecropsy: 'Mail-in Necropsy',
        leukosis: 'Leukosis',
        bluetongue: 'Bluetongue',
        bovineViralDiarrhea: 'Bovine viral diarrhea',
        epizooticHemorrhagicDisease: 'Epizootic hemorrhagic dis.',
        infectiousBovineRhino: 'Infectious bovine rhino',
        respiratorySyncytialVirus: 'Respiratory syncytial virus',
        rotavirus: 'Rotavirus',
        coronavirus: 'Coronavirus',
        distemper: 'Distemper',
        infectiousCanineHepatitis: 'Infectious canine hepatitis',
        parvovirus: 'Parvovirus',
        canineHerpesvirus: 'Canine herpesvirus',
        infectiousPeritonitis: 'Infectious peritonitis',
        leukemia: 'Leukemia',
        panleukopenia: 'Panleukopenia',
        rhinotracheitis: 'Rhinotracheitis (FHVI)',
        tularemia: 'Tularemia',
        chlamydia: 'Chlamydia/Chlamydophila spp',
        cfelisRhino: 'C.felis spp. & Rhino.',
        influenza: 'Influenza (H1N1 & H3N2)',
        parvovirusPorcine: 'Parvovirus',
        pseudorabies: 'Pseudorabies',
        prrs: 'PRRS',
        tge: 'TGE',
        brucellaCanis: 'Brucella canis',
        brucellaAbortus: 'Brucella abortus',
        brucellaSpp: 'Brucella spp.',
        vesicularStomatitisVirus: 'Vesicular stomatitis virus',
        leptospiraSpp: 'Leptospira spp.',
        toxoplasmaSpp: 'Toxoplasma spp.',
        campylobacterSpp: 'Campylobacter spp.',
        chlamydophilaSpp: 'Chlamydophila spp.',
        clostridium: 'Clostridium (Blackleg, Malignant Edema)',
        cryptosporidiumSpp: 'Cryptosporidium spp.',
        giardiaSpp: 'Giardia spp.',
        fungiSpecify: 'Fungi (Specify)',
        influenzaTypeA: 'Influenza type A',
        listeriaSpp: 'Listeria spp',
        mycobacteriumSpp: 'Mycobacterium spp.',
        mycoplasmaSpp: 'Mycoplasma spp.',
        neosporaSpp: 'Neospora spp',
        ecoliPilusAntigen: 'E. coli pilus antigen',
        avianInfluenzaPcr: 'Avian Influenza PCR',
        clostridiumDifficile: 'Clostridium difficile cytotoxic ELISA',
        sealHerpesvirus1Sn: 'Seal herpesvirus 1 SN',
        morbillivirus: 'Morbillivirus',
        newcastleDiseasePcr: 'Newcastle disease PCR',
        pachecosDiseaseFa: 'Pacheco’s disease FA',
        directSalineSmear: 'Direct saline smear',
        fecalEggCount: 'Fecal egg count',
        fecalFloat: 'Fecal float',
        parasiteId: 'Parasite ID',
      },
    },
  },
};
