import styles from './styles.module.scss';

const Cytology = () => {
  return (
    <>
      <tr style={{ height: '10pt' }}>
        <td className={`${styles.cell} ${styles.cellWide}`} colSpan={3}>
          <p
            className={`${styles.paddingSmall} ${styles.textIndentNone} ${styles.lineHeightSmall} ${styles.cellLeft}`}
          >
            <b>Priority</b> Cytology <b>with</b>
          </p>
          <p
            className={`${styles.paddingSmall} ${styles.textIndentNone} ${styles.lineHeightTiny} ${styles.cellLeft}`}
          >
            Microscopic Description
          </p>
        </td>
        <td className={`${styles.cell} ${styles.cellWideAlt}`} colSpan={3}>
          <p
            className={`${styles.paddingMedium} ${styles.textIndentNone} ${styles.lineHeightSmall} ${styles.cellLeft}`}
          >
            <b>Standard</b> Cytology <b>with</b>
          </p>
          <p
            className={`${styles.paddingMedium} ${styles.textIndentNone} ${styles.lineHeightTiny} ${styles.cellLeft}`}
          >
            Microscopic Description
          </p>
        </td>
      </tr>
      <tr style={{ height: '9pt' }}>
        <td
          className={`${styles.cell} ${styles.cellSmall} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMedium} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt', paddingRight: '3pt' }}>605</p>
        </td>
        <td className={`${styles.cell} ${styles.cellLarge} ${styles.cellLeft}`}>
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>1 Site/Lesion</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellSmallAlt} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMediumAlt} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt' }}>2801</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellLargeAlt} ${styles.cellLeft}`}
        >
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>1 Site/Lesion</p>
        </td>
      </tr>
      <tr style={{ height: '9pt' }}>
        <td
          className={`${styles.cell} ${styles.cellSmall} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMedium} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt', paddingRight: '3pt' }}>647</p>
        </td>
        <td className={`${styles.cell} ${styles.cellLarge} ${styles.cellLeft}`}>
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>2 Site/Lesion</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellSmallAlt} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMediumAlt} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt' }}>2802</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellLargeAlt} ${styles.cellLeft}`}
        >
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>2 Site/Lesion</p>
        </td>
      </tr>
    </>
  );
};

const AdditionalRows = () => {
  return (
    <>
      <tr style={{ height: '9pt' }}>
        <td
          className={`${styles.cell} ${styles.cellSmall} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMedium} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt', paddingRight: '3pt' }}>607</p>
        </td>
        <td className={`${styles.cell} ${styles.cellLarge} ${styles.cellLeft}`}>
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>
            Bone Marrow Cytology with Microscopic Description
          </p>
        </td>
      </tr>
      <tr style={{ height: '9pt' }}>
        <td
          className={`${styles.cell} ${styles.cellSmall} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMedium} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt', paddingRight: '3pt' }}>905</p>
        </td>
        <td className={`${styles.cell} ${styles.cellLarge} ${styles.cellLeft}`}>
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>
            Body Cavity Effusion—fluid analysis includes cytology
          </p>
        </td>
      </tr>
      <tr style={{ height: '9pt' }}>
        <td
          className={`${styles.cell} ${styles.cellSmall} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMedium} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt', paddingRight: '3pt' }}>906</p>
        </td>
        <td className={`${styles.cell} ${styles.cellLarge} ${styles.cellLeft}`}>
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>
            Synovial/Joint—fluid analysis includes cytology
          </p>
        </td>
      </tr>
      <tr style={{ height: '9pt' }}>
        <td
          className={`${styles.cell} ${styles.cellSmall} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMedium} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt', paddingRight: '3pt' }}>900</p>
        </td>
        <td className={`${styles.cell} ${styles.cellLarge} ${styles.cellLeft}`}>
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>
            Cerebral Spinal Fluid (CSF)—fluid analysis includes cytology
          </p>
        </td>
      </tr>
      <tr style={{ height: '9pt' }}>
        <td
          className={`${styles.cell} ${styles.cellSmall} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMedium} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt', paddingRight: '3pt' }}>935</p>
        </td>
        <td className={`${styles.cell} ${styles.cellFull} ${styles.cellLeft}`}>
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>
            Bronchioalveolar Lavage (BAL) Cell Count—fluid analysis includes
            cytology
          </p>
        </td>
      </tr>
    </>
  );
};

const Byopsy = () => {
  return (
    <>
      <tr style={{ height: '10pt' }}>
        <td className={`${styles.cell} ${styles.cellWide}`} colSpan={3}>
          <p
            className={`${styles.paddingSmall} ${styles.textIndentNone} ${styles.lineHeightSmall} ${styles.cellLeft}`}
          >
            <b>Priority</b> Biopsy <b>with</b>
          </p>
          <p
            className={`${styles.paddingSmall} ${styles.textIndentNone} ${styles.lineHeightTiny} ${styles.cellLeft}`}
          >
            Microscopic Description
          </p>
        </td>
        <td className={`${styles.cell} ${styles.cellWideAlt}`} colSpan={3}>
          <p
            className={`${styles.paddingMedium} ${styles.textIndentNone} ${styles.lineHeightSmall} ${styles.cellLeft}`}
          >
            <b>Standard</b> Biopsy <b>with</b>
          </p>
          <p
            className={`${styles.paddingMedium} ${styles.textIndentNone} ${styles.lineHeightTiny} ${styles.cellLeft}`}
          >
            Microscopic Description
          </p>
        </td>
      </tr>
      <tr style={{ height: '9pt' }}>
        <td
          className={`${styles.cell} ${styles.cellSmall} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMedium} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt', paddingRight: '3pt' }}>601</p>
        </td>
        <td className={`${styles.cell} ${styles.cellLarge} ${styles.cellLeft}`}>
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>1 Site/Lesion</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellSmallAlt} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMediumAlt} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt' }}>2701</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellLargeAlt} ${styles.cellLeft}`}
        >
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>1 Site/Lesion</p>
        </td>
      </tr>
      <tr style={{ height: '9pt' }}>
        <td
          className={`${styles.cell} ${styles.cellSmall} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMedium} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt', paddingRight: '3pt' }}>647</p>
        </td>
        <td className={`${styles.cell} ${styles.cellLarge} ${styles.cellLeft}`}>
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>2 Site/Lesion</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellSmallAlt} ${styles.cellCenter}`}
        >
          <p>□</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellMediumAlt} ${styles.cellCenter}`}
        >
          <p style={{ paddingTop: '1pt' }}>2702</p>
        </td>
        <td
          className={`${styles.cell} ${styles.cellLargeAlt} ${styles.cellLeft}`}
        >
          <p style={{ paddingTop: '1pt', paddingLeft: '2pt' }}>2 Site/Lesion</p>
        </td>
      </tr>
    </>
  );
};

const Source = () => {
  return (
    <>
      <tr className={styles.importantTableRow}>
        <td className={styles.importantTableCell} colSpan={6}>
          <p className={`${styles.textRegular} ${styles.textLeft}`}>
            <b>IMPORTANT:</b>{' '}
            <span className={styles.textRegularSmall}>Providing</span>{' '}
            <b>SOURCE</b> <span className={styles.textRegularSmall}>and</span>{' '}
            <b>HISTORY</b> <span className={styles.textRegularSmall}>IS</span>{' '}
            <b>CRITICAL</b>
          </p>
          <p className={`${styles.textRegularSmall} ${styles.textLeft}`}>
            for pathologist interpretation.
          </p>
          <p
            className={`${styles.textRegularSmall} ${styles.paddingTop4} ${styles.textLeft}`}
          >
            Label{' '}
            <span className={styles.noteTextBold}>
              all cytology slides AND all containers
            </span>{' '}
            with client name, patient name, and specimen source.
          </p>
          <p className={styles.textLeft}></p>
          <p
            className={`${styles.textRegularSmall} ${styles.paddingTop4} ${styles.textLeft}`}
          >
            # of sites/tumors/lesions:
          </p>
          <p
            className={`${styles.textRegularSmall} ${styles.paddingTop4} ${styles.textLeft}`}
          >
            Source(s):{' '}
            <span className={styles.underline}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </p>
        </td>
      </tr>
      <tr className={styles.historyTableRow}>
        <td className={styles.historyTableCell} colSpan={6}>
          <p
            className={`${styles.textRegularSmall} ${styles.paddingTop4} ${styles.textLeft}`}
          >
            Applicable history:{' '}
            <span className={styles.underline}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </p>
        </td>
      </tr>
      <tr className={styles.emptyRow}>
        <td className={styles.historyTableCell} colSpan={6}>
          <p className={styles.textLeft}>
            <br />
          </p>
        </td>
      </tr>
      <tr className={styles.emptyRow}>
        <td className={styles.historyTableCell} colSpan={6}>
          <p className={styles.textLeft}>
            <br />
          </p>
        </td>
      </tr>
      <tr className={styles.locationTableRow}>
        <td className={styles.historyTableCell} colSpan={6}>
          <p className={`${styles.textRegularSmall} ${styles.textLeft}`}>
            Location:{' '}
            <span className={styles.underline}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </p>
          <table cellPadding="0">
            <tbody>
              <tr>
                <td>
                  <img
                    style={{ width: '100%', height: 'auto' }}
                    src="/IDEXX_pet-Location.jpg"
                    alt="Location"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className={`${styles.noteTextBold} ${styles.paddingTop6} ${styles.textLeft}`}
          >
            NOTE: Incremental fee will be charged for each site/lesion.{' '}
            <span className={styles.textRegularSmall}>For</span>
          </p>
          <p
            className={`${styles.noteTextRegular} ${styles.paddingRight2} ${styles.textLeft}`}
          >
            3 or more sites/lesions, additional pathology tests, protocol
            information, and pathology fee policies, refer to the Online Test
            Directory at vetconnectplus.com.
          </p>
        </td>
      </tr>
    </>
  );
};

const CytologyTable = () => {
  return (
    <>
      <p className={styles.tableName}>
        <b>CYTOLOGY and HISTOPATHOLOGY</b>
      </p>
      <table className={styles.table} cellSpacing="0">
        <tbody>
          <Cytology />
        </tbody>
      </table>
      <table className={styles.table} cellSpacing="0">
        <tbody>
          <AdditionalRows />
        </tbody>
      </table>
      <table className={styles.table} cellSpacing="0">
        <tbody>
          <Byopsy />
        </tbody>
      </table>
      <table className={styles.table} cellSpacing="0">
        <tbody>
          <Source />
        </tbody>
      </table>
    </>
  );
};

export default CytologyTable;
