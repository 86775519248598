import styles from './styles.module.scss';

const MicrobiologyTable = () => {
  return (
    <>
      <p className={styles.tableName}>
        <b>MICROBIOLOGY</b>
      </p>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td className={styles.checkboxCell}>□</td>
            <td className={styles.codeCell}>400</td>
            <td className={styles.descriptionCell}>
              Aerobic Culture (ID and Sus)
            </td>
            <td className={styles.emptyCell}></td>
          </tr>
          <tr>
            <td className={styles.checkboxCell}>□</td>
            <td className={styles.codeCell}>401</td>
            <td className={styles.descriptionCell}>
              Aerobic and Anaerobic Cultures
            </td>
            <td className={styles.emptyCell}></td>
          </tr>
          <tr>
            <td className={styles.checkboxCell}>□</td>
            <td className={styles.codeCell}>405</td>
            <td className={styles.descriptionCell}>Fungal Culture</td>
            <td className={styles.emptyCell}></td>
          </tr>
          <tr>
            <td className={styles.checkboxCell}>□</td>
            <td className={styles.codeCell}>4035</td>
            <td colSpan={2} className={styles.descriptionCell}>
              Urine Culture and MIC Susceptibility
              <ul className={styles.list}>
                <p>□ Cysto □ Catheter □ Other</p>
              </ul>
            </td>
          </tr>
          <tr>
            <td className={styles.checkboxCell}>□</td>
            <td className={styles.codeCell}>1394</td>
            <td colSpan={2} className={styles.descriptionCell}>
              Urinalysis, Urine Culture and MIC Susceptibility
              <ul className={styles.list}>
                <p>□ Cysto □ Catheter □ Other</p>
              </ul>
            </td>
          </tr>
          <tr>
            <td className={styles.checkboxCell}>□</td>
            <td className={styles.codeCell}>9430</td>
            <td colSpan={2} className={styles.descriptionCell}>
              Urine Culture and MIC Susceptibility (if Indicated)
              <ul className={styles.list}>
                <p>□ Cysto □ Catheter □ Other</p>
              </ul>
            </td>
          </tr>
          <tr>
            <td className={styles.checkboxCell}>□</td>
            <td className={styles.codeCell}>3901</td>
            <td colSpan={2} className={styles.descriptionCell}>
              Urinalysis with Urine Culture and MIC Susceptibility (if
              Indicated)
              <p>□ Cysto □ Catheter □ Other</p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default MicrobiologyTable;
