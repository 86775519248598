import { Col, Layout, Row } from 'antd';
import { MainLayout } from 'modules/common/components';
import PrivacyPolicyContent from 'modules/user/components/PrivacyPolicyContent';
import { FC } from 'react';

import styles from './styles.module.scss';

const PrivacyPolicyPage: FC = () => {
  return (
    <MainLayout>
      <Layout.Content className={styles.content}>
        <Row justify="center" align="middle" className={styles.container}>
          <Col span={20} xl={16}>
            <PrivacyPolicyContent />
          </Col>
        </Row>
      </Layout.Content>
    </MainLayout>
  );
};

export default PrivacyPolicyPage;
