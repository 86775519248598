import { FC } from 'react';

import styles from './styles.module.scss';

interface ITableProps {
  tableData: {
    id: string;
    description: string;
    additional?: string;
  }[];
  title: string;
  subtitle?: string;
}

const Table: FC<ITableProps> = ({ tableData, title, subtitle }) => {
  return (
    <>
      <p className={styles.tableName}>
        <b>{title}</b>
      </p>
      {subtitle && <p>All profiles require serum and lavender-top tube</p>}
      <table className={styles.table}>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={index}>
              <td className={styles.cell}>□</td>
              <td className={styles.cell}>{item.id}</td>
              <td className={styles.cell}>{item.description}</td>
              {item.additional && (
                <ul>
                  <li style={{ paddingLeft: '10pt', textAlign: 'left' }}>
                    {item.additional}
                  </li>
                </ul>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Table;
