export const serology = [
  { id: '24', description: 'FeLV Ag and FIV Ab by ELISA' },
  { id: '723', description: 'Canine Heartworm Ag by ELISA' },
  { id: '707', description: 'Equine Infectious Anemia by AGID (Coggins)' },
  { id: '7077', description: 'Equine Infectious Anemia by ELISA' },
  { id: '709', description: 'FeLV Ag by ELISA' },
  { id: '710', description: 'Feline Coronavirus (FCoV) Ab by IFA' },
  { id: '7244', description: 'Lab 4Dx® Plus Test—Canine' },
  { id: '3755', description: 'Lab Feline Triple® Test' },
  { id: '7246', description: 'Lyme Quant C6® Antibody Test' },
];
