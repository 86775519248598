import { AnimalSpecies } from 'types.d';

import styles from '../../styles.module.scss';

type SpecialTestRequestsProps = {
  species: AnimalSpecies;
};

const SpecialTestRequests = ({ species }: SpecialTestRequestsProps) => (
  <div className={styles.textbox}>
    <p className={styles.title}>
      <b>SPECIAL TEST REQUESTS</b>
    </p>
    <p className={styles.subtitle}>
      Code #: 2909 Test name “Blood Donor RealPCR Panel with Lab4Dx Plus-
      {species === AnimalSpecies.Dog && 'Canine'}
      {species === AnimalSpecies.Cat && 'Feline'}”
    </p>
  </div>
);

export default SpecialTestRequests;
