import { ControlledInput } from 'modules/common/components';
import { Control, FormState } from 'react-hook-form';
import { getTranslation } from 'utils/getTranslation';

import { IAddLocationsFormSchema } from './schema';

export enum AddLocationsFormField {
  Name = 'name',
  Type = 'type',
  accountingCode = 'accounting code',
}

type ParamType = {
  control: Control<IAddLocationsFormSchema, any>;
};
type FormErrorsType = FormState<IAddLocationsFormSchema>['errors'];

const t = getTranslation('user.CreateLocationsForm');

export const getFormFields = (params: ParamType) => {
  const { control } = params;
  const editLocationsFormFields = [
    {
      name: AddLocationsFormField.Name,
      getErrorMessage: (errors: FormErrorsType) => errors.name?.message,
      Component: () => (
        <ControlledInput
          placeholder={t('enterRegionName')}
          controlled={{
            control,
            name: AddLocationsFormField.Name,
          }}
        />
      ),
    },
    {
      name: AddLocationsFormField.accountingCode,
      getErrorMessage: (errors: FormErrorsType) => errors.name?.message,
      Component: () => (
        <ControlledInput
          placeholder={t('accountingCode')}
          controlled={{
            control,
            name: AddLocationsFormField.accountingCode,
          }}
        />
      ),
    },
  ];
  return editLocationsFormFields;
};
