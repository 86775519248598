import { gql } from '@apollo/client';
import { USER_OUTPUT_FRAGMENT } from 'modules/user/graphql/fragments';

export const DONOR_FRAGMENT = gql`
  fragment Donor on DonorOutput {
    id
    age
    gender
    hasTransfusion
    name
    neutered
    primaryVeterinarian
    species
    takeMedications
    weight
    approvedDonationML
    donationMLApprovedAmount
    bloodType
    status
    type
    location
    distinguishableMarks
    microchipNumber
    medicationCourse
    comment
    breed
    color
    createdAt
    shortId
    firstCertificatePrinted
    secondCertificatePrinted
    becameReservedDonorDate
    retiredDate
    distemperDate
    rabiesDate
    lastDonationDate
    nextDonationDate
    lastLabworkDate
    hasMicrochip
    acceptSimparicaTrio
    isDaycare
    donationFrequency
    canRegularlyAttendDaycare
    attendDaycareDays
    retiredReason
    retiredReasonComment
    dateOfBirth
  }
`;

export const PARTIAL_DONOR_FRAGMENT = gql`
  fragment PartialDonor on PartialDonorOutput {
    id
    age
    gender
    hasTransfusion
    name
    neutered
    primaryVeterinarian
    species
    takeMedications
    weight
    approvedDonationML
    donationMLApprovedAmount
    bloodType
    status
    type
    location
    distinguishableMarks
    microchipNumber
    medicationCourse
    comment
    breed
    color
    createdAt
    shortId
    firstCertificatePrinted
    secondCertificatePrinted
    becameReservedDonorDate
    retiredDate
    distemperDate
    rabiesDate
    lastDonationDate
    nextDonationDate
    lastLabworkDate
    hasMicrochip
    acceptSimparicaTrio
    isDaycare
    donationFrequency
    canRegularlyAttendDaycare
    attendDaycareDays
    retiredReason
    retiredReasonComment
    dateOfBirth
  }
`;

export const TEAM_FRAGMENT = gql`
  fragment Team on TeamOutput {
    id
    title
  }
`;

export const GROUP_TEAM_FRAGMENT = gql`
  fragment GroupTeam on GroupTeamOutput {
    id
    title
  }
`;

export const GROUP_FRAGMENT = gql`
  fragment Group on GroupOutput {
    id
    title
  }
`;

export const GROUP_LIST_ITEM_FRAGMENT = gql`
  fragment GroupListItem on GroupListItemOutput {
    teamCount
    donorCount
    title
    user {
      firstName
      lastName
      id
    }
    id
  }
`;

export const RESERVED_DONOR_NOTE_FRAGMENT = gql`
  fragment ReserveDonorNote on ReserveDonorNoteOutput {
    id
    text
    createdAt
  }
`;

export const DONOR_NOTE_FRAGMENT = gql`
  fragment DonorNote on DonorNoteOutput {
    id
    text
    isPinned
    files {
      id
      file {
        id
        filename
      }
    }
    createdAt
    updatedAt
    user {
      ...User
    }
  }

  ${USER_OUTPUT_FRAGMENT}
`;

export const LABWORK_FRAGMENT = gql`
  fragment Labwork on LabworkOutput {
    id
    collectionDate
    status
    DVMExam
  }
`;

export const PICTURE_FRAGMENT = gql`
  fragment Picture on DonorPictureOutput {
    id
    file {
      id
      filename
    }
  }
`;

export const FILE_FRAGMENT = gql`
  fragment File on FileOutput {
    id
    filename
  }
`;

export const VITALS_PARAMETER_FRAGMENT = gql`
  fragment VitalsParameter on VitalsParameterOutput {
    id
    criticalValues
    type
    max
    min
    percentageDifference
  }
`;

export const LABWORK_ATTACHMENT_FRAGMENT = gql`
  fragment LabworkAttachment on LabworkAttachmentOutput {
    id
    createdAt
    updatedAt
  }
`;
