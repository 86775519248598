import { ContactsFormSectionField } from 'modules/donor/components/RoutineTestForm/fields';
import { RoutineTestGender } from 'modules/donor/components/RoutineTestGenderSelect/enum';
import {
  petSpeciesSchema,
  petAgeSchema,
  routineTestGenderSchema,
} from 'modules/donor/schemas';
import { AnimalSpecies } from 'types.d';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

const t = getTranslation('donor.ValidationErrors');

const {
  Veterinarian,
  OwnerLastName,
  AnimalName,
  CollectionDate,
  Species,
  Breed,
  Age,
  Gender,
  PatientID,
  StaffPet,
  Notes,
  DonorId,
  DVMExam,
} = ContactsFormSectionField;

type RoutineTestFormSchemaType = {
  donorId: string;
  veterinarian: string;
  ownerLastName: string;
  animalName: string;
  collectionDate: Date;
  species: AnimalSpecies;
  breed: string;
  age: number;
  gender: RoutineTestGender;
  patientID: string;
  staffPet?: boolean;
  notes?: string;
};

type CreateLabworkParamType = {
  dvmExam: boolean;
};

const routineTestFormSchemaType: yup.SchemaOf<RoutineTestFormSchemaType> =
  yup.object({
    [DonorId]: yup.string().required(),
    [Veterinarian]: yup
      .string()
      .required(t('required', { field: t('veterinarian') })),
    [OwnerLastName]: yup
      .string()
      .required(t('required', { field: t('ownerLastName') })),
    [AnimalName]: yup
      .string()
      .required(t('required', { field: t('animalName') })),
    [CollectionDate]: yup
      .date()
      .typeError(t('mustBeDate'))
      .required(t('required', { field: t('collectionDate') })),
    [Species]: petSpeciesSchema,
    [Breed]: yup.string().required(t('required', { field: t('breed') })),
    [Age]: petAgeSchema,
    [Gender]: routineTestGenderSchema,
    [PatientID]: yup
      .string()
      .required(t('required', { field: t('patientID') })),
    [StaffPet]: yup.boolean(),
    [Notes]: yup.string(),
  });

const createLabworkFormSchemaType: yup.SchemaOf<CreateLabworkParamType> =
  yup.object({
    [DVMExam]: yup.boolean().required(),
  });

export { routineTestFormSchemaType, createLabworkFormSchemaType };
export type { RoutineTestFormSchemaType, CreateLabworkParamType };
