import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const Footer: FC = () => {
  const { t } = useTranslation('donor.FormsToPrint.IDEXXSubmissionForm');

  return (
    <div className={styles.container}>
      <div></div>
      <div className={styles.text}>{t('footerText')}</div>
      <div className={styles.number}>{t('footerNumber')}</div>
    </div>
  );
};

export default Footer;
