import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CytologyTable,
  DonorInfo,
  Footer,
  Head,
  MicrobiologyTable,
  Table,
  SpecialTestRequest,
} from './components';
import {
  canine,
  chemistryPanel,
  drugMonitoring,
  endocrinology,
  fecalsTests,
  felineTests,
  hematology,
  profiles,
  serology,
  stoneAnalysis,
} from './defaultData';
import styles from './styles.module.scss';

import { RoutineTestFormSchemaType } from '../../RoutineTestForm/schema';

type PropTypes = {
  data: RoutineTestFormSchemaType;
};

const IDEXXSubmissionForm = forwardRef<any, PropTypes>(({ data }, ref) => {
  const { t } = useTranslation('donor.FormsToPrint.IDEXXSubmissionForm');

  return (
    <div className={styles.formWrapper} ref={ref}>
      <Head collectionDate={data.collectionDate} />
      <div className={styles.content}>
        <div>
          <DonorInfo
            ownerLastName={data.ownerLastName}
            animalName={data.animalName}
            patientId={data.patientID}
            breed={data.breed}
            sex={data.gender}
            species={data.species}
            age={data.age}
            veterinarian={data.veterinarian}
          />
          <Table tableData={canine} title={t('canine')} />
          <Table tableData={felineTests} title={t('feline')} />
          <Table tableData={fecalsTests} title={t('fecals')} />
          <Table tableData={hematology} title={t('hematology')} />
        </div>
        <div>
          <SpecialTestRequest species={data.species} />
          <Table
            tableData={profiles}
            title={t('profilesTitle')}
            subtitle={t('profilesSubtitle')}
          />
          <Table tableData={endocrinology} title={t('endocrinology')} />
          <Table tableData={chemistryPanel} title={t('chemistry')} />
          <Table tableData={drugMonitoring} title={t('therapeutic')} />
        </div>
        <div>
          <CytologyTable />
          <Table tableData={stoneAnalysis} title={t('urine')} />
          <MicrobiologyTable />
          <Table tableData={serology} title={t('serology')} />
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default IDEXXSubmissionForm;
