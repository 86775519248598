import { Col, Row } from 'antd';
import { Routes } from 'config/routes';
import {
  ControlledCheckbox,
  ControlledSelect,
  ControlledTextArea,
  FormItem,
  Link,
  Text,
} from 'modules/common/components';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import { PreviousDonationValue } from 'modules/donations/components';
import styles from 'modules/donations/components/CreateDonationForm/styles.module.scss';
import { ExaminationFormFiledType } from 'modules/donations/components/ExaminationSection/fields';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DonationOutput, IneligibleReason } from 'types.d';

import { ExaminationFields } from '../../enum';
import { examinationSchema } from '../../schema';

type Props = {
  fieldNamePrefix: string;
  fields: ExaminationFormFiledType[];
  previousDonation?: DonationOutput;
  showLinkToPreviousDonation?: boolean;
  sectionNamePrefix?: string;
  formEnabled?: boolean;
};

const MobileExaminationSectionView: FC<Props> = ({
  fieldNamePrefix,
  fields,
  previousDonation,
  showLinkToPreviousDonation,
  sectionNamePrefix,
  formEnabled,
}) => {
  const formContext = useFormContext();
  const { t } = useTranslation('donation.CreateDonationForm.Examination');
  const isRequired = getIsRequiredInSchema(examinationSchema);

  const withPrefix = (fieldName: ExaminationFields): string => {
    const sectionName = sectionNamePrefix ? sectionNamePrefix + '.' : '';
    return `${sectionName}.${fieldNamePrefix}.${fieldName}`;
  };

  const isIneligible: boolean | undefined = formContext.watch(
    withPrefix(ExaminationFields.ineligible)
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        {fields.map(({ name, Component, label }) => {
          const fieldName = withPrefix(name);
          const sectionErrors = sectionNamePrefix
            ? formContext.formState.errors[sectionNamePrefix]
            : formContext.formState.errors;
          const errorMessage =
            sectionErrors?.[fieldNamePrefix]?.[name]?.message;

          return (
            <Col span={24} key={name}>
              <Col>
                <FormItem
                  className={styles.formItem}
                  extra={errorMessage}
                  validateStatus={errorMessage && 'error'}
                  label={label}
                  required={isRequired(name)}
                >
                  <Component control={formContext.control} name={fieldName} />
                </FormItem>
              </Col>
              <Col>
                {previousDonation && (
                  <Text italic>
                    {`${t('previousDonation')}:`}
                    <Text code>
                      <PreviousDonationValue
                        value={previousDonation?.[name as keyof DonationOutput]}
                      />
                    </Text>
                  </Text>
                )}
              </Col>
            </Col>
          );
        })}
        {showLinkToPreviousDonation && (
          <Col span={24}>
            <Link to={`${Routes.Donations}/${previousDonation?.shortId}`}>
              {t('previousDonation')}
            </Link>
          </Col>
        )}
        <Col span={24}>
          <FormItem className={styles.formItem}>
            <ControlledCheckbox
              disabled={!formEnabled}
              controlled={{
                control: formContext.control,
                name: withPrefix(ExaminationFields.ineligible),
              }}
            >
              {t('donationIneligible')}
            </ControlledCheckbox>
          </FormItem>
        </Col>
        {isIneligible && (
          <>
            <Col span={24}>
              <FormItem
                label={t('ineligibleReason')}
                className={styles.formItem}
              >
                <ControlledSelect
                  disabled={!formEnabled}
                  controlled={{
                    control: formContext.control,
                    name: withPrefix(ExaminationFields.ineligibleReason),
                  }}
                  options={Object.values(IneligibleReason).map((value) => ({
                    value,
                    label: t(value),
                  }))}
                  placeholder={t('selectReason')}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={t('ineligibleReason')}
                className={styles.formItem}
              >
                <ControlledTextArea
                  disabled={!formEnabled}
                  controlled={{
                    control: formContext.control,
                    name: withPrefix(ExaminationFields.ineligibleReason),
                  }}
                />
              </FormItem>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default MobileExaminationSectionView;
