export const chemistryPanel = [
  {
    id: '3638',
    description: 'IDEXX SDMA® Test (stand-alone)',
  },
  {
    id: '257',
    description: 'Bile Acids Panel (Pre- and Postprandial)',
  },
  {
    id: '111',
    description: 'Chem 25 with IDEXX SDMA® Test',
  },
  {
    id: '1113',
    description: 'Chem 27 with IDEXX SDMA® Test',
  },
];
