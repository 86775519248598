import { gql } from '@apollo/client';
import { REGION_FRAGMENT } from 'modules/locations/graphql/fragments';

export const PRODUCT_FRAGMENT = gql`
  fragment Product on ProductOutput {
    anticoagulant
    createdAt
    id
    shortId
    status
    storageRequirements
    updatedAt
    value
    woocommerceProductId
    woocommerceCategoryId
    woocommerceOrderId
    expirationDate
    published
    regionPreference {
      ...Region
    }
  }
  ${REGION_FRAGMENT}
`;
