/* eslint-disable unused-imports/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { DefaultOptionType } from 'antd/lib/select';
import config from 'config';
import { defaultClinicParams } from 'config/default-clinic-params';
import { Select } from 'modules/common/components';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import { DonorOutput, AnimalGender, LabworkOutput } from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import styles from './styles.module.scss';

import {
  StateLabSubmissionForm,
  GeneralSubmissionForm,
  AthensVeterinaryDiagnostic,
  IDEXXSubmissionForm,
} from '../LabworkFormToPrint';
import RoutineTest from '../RoutineTest';
import {
  routineTestFormFields,
  ContactsFormSectionField,
} from '../RoutineTestForm/fields';
import {
  CreateLabworkParamType,
  RoutineTestFormSchemaType,
  routineTestFormSchemaType,
} from '../RoutineTestForm/schema';
import { RoutineTestGender } from '../RoutineTestGenderSelect/enum';

type PropTypes = {
  labwork: LabworkOutput;
  setIsPrintButtonDisabled: (isDisabled: boolean) => void;
};

export type PrintHandleRef = {
  handlePrint: () => void;
};

enum FormToPrint {
  RoutineTestForm = 'RoutineTest',
  StateLabSubmissionForm = 'StateLabSubmissionForm',
  GeneralSubmissionForm = 'GeneralSubmissionForm',
  AthensVeterinaryDiagnostic = 'AthensVeterinaryDiagnostic',
  IDEXXSubmissionForm = 'IDEXXSubmissionForm',
}

const t = getTranslation('donor.PrintLabworkForm');

const SelectFormToPrintOptions: DefaultOptionType[] = [
  {
    label: t('routineTest'),
    value: FormToPrint.RoutineTestForm,
  },
  {
    label: t('athensVeterinaryDiagnostic'),
    value: FormToPrint.AthensVeterinaryDiagnostic,
  },
  {
    label: t('stateLabSubmissionForm'),
    value: FormToPrint.StateLabSubmissionForm,
  },
  {
    label: t('generalSubmissionForm'),
    value: FormToPrint.GeneralSubmissionForm,
  },
  {
    label: t('IDEXXSubmissionForm'),
    value: FormToPrint.IDEXXSubmissionForm,
  },
];

const PrintLabworkForm = forwardRef<PrintHandleRef, PropTypes>(
  ({ labwork, setIsPrintButtonDisabled }, ref) => {
    const printLabworkForm = useForm<
      RoutineTestFormSchemaType & CreateLabworkParamType
    >({
      resolver: yupResolver(routineTestFormSchemaType),
      mode: 'onChange',
    });

    const testRef = useRef(null);
    const [formToPrint, setFormToPrint] = useState<FormToPrint | null>(null);

    const handlePrint = useReactToPrint({
      content: () => testRef.current,
      documentTitle: `${formToPrint}_${labwork?.donor.shortId}`,
    });

    useImperativeHandle(
      ref,
      () => ({
        handlePrint,
      }),
      [formToPrint]
    );

    useEffect(() => {
      setIsPrintButtonDisabled(
        !printLabworkForm.formState.isValid || !formToPrint
      );
    }, [printLabworkForm.formState, formToPrint]);

    const convertDonorGender = (donor: DonorOutput) => {
      return donor.gender === AnimalGender.Male
        ? donor.neutered
          ? RoutineTestGender.MN
          : RoutineTestGender.M
        : donor.neutered
        ? RoutineTestGender.FS
        : RoutineTestGender.F;
    };

    const formFields = useMemo(() => routineTestFormFields, [labwork]);

    useEffect(() => {
      const donor = labwork?.donor;
      if (donor) {
        printLabworkForm.reset({
          animalName: donor.name,
          species: donor.species,
          breed: donor.breed || '',
          age: donor.age,
          gender: convertDonorGender(donor),
          ownerLastName: donor.user?.lastName,
          patientID: donor.shortId.toString(),
          veterinarian: config.DEFAULT_VETERINARIAN,
          donorId: donor.id,
          collectionDate: new Date(labwork.collectionDate),
        });
      }
    }, [labwork]);

    const formData = {
      ...defaultClinicParams,
      ...printLabworkForm.getValues(),
    };

    return (
      <Row>
        <Col span={24}>
          <FormProvider {...printLabworkForm}>
            <Form layout="vertical">
              <Row>
                <Col span={24}>
                  {formFields.map(({ Component, label, name }) => {
                    const errorMessage =
                      printLabworkForm.formState.errors?.[name]?.message;
                    if (
                      name === ContactsFormSectionField.StaffPet ||
                      name === ContactsFormSectionField.DVMExam ||
                      name === ContactsFormSectionField.Notes
                    ) {
                      return null;
                    }
                    return (
                      <Col span={24} key={name}>
                        <FormItem
                          label={label}
                          extra={errorMessage}
                          validateStatus={errorMessage && 'error'}
                        >
                          <Component
                            control={printLabworkForm.control}
                            name={name}
                          />
                        </FormItem>
                      </Col>
                    );
                  })}
                  <FormItem label={t('formToPrint')}>
                    <Select
                      adjustDropdownPosition={true}
                      options={SelectFormToPrintOptions}
                      value={formToPrint}
                      onChange={setFormToPrint}
                      className={styles.formSelect}
                      placeholder={t('selectPlaceholder')}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </FormProvider>

          <Col span={0}>
            {formToPrint === FormToPrint.RoutineTestForm && (
              <RoutineTest ref={testRef} data={printLabworkForm.getValues()} />
            )}
            {formToPrint === FormToPrint.StateLabSubmissionForm && (
              <StateLabSubmissionForm ref={testRef} data={formData} />
            )}
            {formToPrint === FormToPrint.GeneralSubmissionForm && (
              <GeneralSubmissionForm ref={testRef} data={formData} />
            )}
            {formToPrint === FormToPrint.AthensVeterinaryDiagnostic && (
              <AthensVeterinaryDiagnostic ref={testRef} data={formData} />
            )}
            {formToPrint === FormToPrint.IDEXXSubmissionForm && (
              <IDEXXSubmissionForm ref={testRef} data={formData} />
            )}
          </Col>
        </Col>
      </Row>
    );
  }
);

export default PrintLabworkForm;
