import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { AnimalSpecies } from 'types.d';

import styles from './styles.module.scss';

interface IDonorInfoProps {
  ownerLastName: string;
  animalName: string;
  patientId: string;
  breed: string;
  sex: string;
  species: AnimalSpecies;
  age: number;
  veterinarian: string;
}

const DonorInfo: FC<IDonorInfoProps> = ({
  ownerLastName,
  animalName,
  patientId,
  species,
  breed,
  sex,
  age,
  veterinarian,
}) => {
  const { data } = useCurrentUser();
  const accountingCode = data?.productRegionPreference?.accountingCode;

  return (
    <div>
      <div className={styles.emptyBox}>{accountingCode || ''}</div>
      <div className={styles.infoElement}>
        <p>Please send duplicate results to:</p>
        <p>Fax # ______________________________</p>
      </div>
      <div className={styles.infoElement}>
        <p>Patient ID# (optional)</p>
        <div className={styles.fieldValues}>
          <p>{patientId}</p>
        </div>
      </div>
      <div className={styles.emptyBox} style={{ marginTop: '5px' }}></div>
      <div className={styles.infoElement}>
        <p>OWNER LAST NAME</p>
        <div className={styles.fieldValues}>
          <p>{ownerLastName}</p>
        </div>
      </div>
      <div className={styles.infoElement}>
        <p>PATIENT NAME</p>
        <div className={styles.fieldValues}>
          <p>{animalName}</p>
        </div>
      </div>
      <table className={styles.table} cellSpacing="0">
        <tbody>
          <tr className={styles.infoElement}>
            <p>SEX</p>
            <td className={styles.checkboxContainer}>
              <p className={styles.cellContent}>
                <input type="checkbox" checked={sex.includes('M')} readOnly />{' '}
                <span>Male</span>{' '}
              </p>
              <p className={styles.cellContent}>
                <input type="checkbox" checked={sex.includes('F')} readOnly />{' '}
                <span>Female</span>
              </p>
              <p className={styles.cellContent}>
                <input
                  type="checkbox"
                  checked={sex.includes('S') || sex.includes('N')}
                  readOnly
                />{' '}
                <span>Spay/Neuter</span>
              </p>
            </td>
          </tr>
          <tr className={styles.infoElement}>
            <p>SPECIES</p>
            <td className={styles.checkboxContainer}>
              <p className={styles.cellContent}>
                <input
                  type="checkbox"
                  checked={species === AnimalSpecies.Dog}
                  readOnly
                />{' '}
                <span>Canine</span>{' '}
              </p>
              <p className={styles.cellContent}>
                <input
                  type="checkbox"
                  checked={species === AnimalSpecies.Cat}
                  readOnly
                />{' '}
                <span>Feline</span>{' '}
              </p>
              <p className={styles.cellContent}>
                <input type="checkbox" checked={false} readOnly />{' '}
                <span>Equine</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className={styles.infoElement}>AGE__{age}__Years__________Months</p>
      <p className={styles.infoElement}>BREED__{breed}__</p>
      <div className={styles.veterinarian}>
        <p>VETERINARIAN(S)</p>
        <p>{veterinarian}</p>
      </div>
    </div>
  );
};

export default DonorInfo;
