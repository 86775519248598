import {
  emailSchema,
  passwordSchema,
  usernameSchema,
} from 'modules/user/schemas';
import { getTranslation } from 'utils/getTranslation';
import * as yup from 'yup';

import { SignUpFields } from './enum';

const t = getTranslation('user.SignUpForm');

const { email, password, confirmPassword, firstName, lastName } = SignUpFields;

type SignUpSchemaType = Record<SignUpFields, string> & {
  privacyPolicyAgreement?: boolean;
};

const signUpSchema: yup.SchemaOf<SignUpSchemaType> = yup.object({
  [firstName]: usernameSchema.required(t('firstNameRequired')),
  [lastName]: usernameSchema.required(t('lastNameRequired')),
  [email]: emailSchema,
  [password]: passwordSchema,
  [confirmPassword]: yup
    .string()
    .required(t('confirmRequired'))
    .oneOf([yup.ref('password'), null], t('passwordMustMatch')),
  privacyPolicyAgreement: yup.boolean(),
});

export { signUpSchema };
export type { SignUpSchemaType };
