type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

const removeDeepUndefinedFields = <ObjectType extends Record<string, any>>(
  data: ObjectType
): DeepPartial<ObjectType> => {
  if (typeof data !== 'object') {
    return data;
  }

  return Object.keys(data).reduce(function (accumulator, key) {
    const isObject = data[key] !== null && typeof data[key] === 'object';
    let value = isObject ? removeDeepUndefinedFields(data[key]) : data[key];
    const isEmptyObject = isObject && !Object.keys(value).length;
    if (value === undefined || isEmptyObject) {
      return accumulator;
    }
    if (Array.isArray(data[key])) {
      value = Object.values(value);
    }

    return Object.assign(accumulator, { [key]: value });
  }, {} as DeepPartial<ObjectType>);
};

export { removeDeepUndefinedFields };
