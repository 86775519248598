import { Button, Row, Col, message, Spin, Grid } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { useModal, useSort } from 'modules/common/hooks';
import LabworkCardList from 'modules/donor/components/LabworkCardList';
import LabworkTable from 'modules/donor/components/LabworkTable';
import RoutineTestModal from 'modules/donor/components/RoutineTestModal';
import { GET_LABWORK_LIST } from 'modules/donor/graphql/queries';
import { usePagination } from 'modules/navigation/hooks/usePagination';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetLabworkListQuery,
  useUpdateLabworkMutation,
  LabworkOutput,
  LabworkListSort,
  useUpdateLabworkStatusMutation,
  LabworkStatus,
  useGetDonorQuery,
  useRemoveLabworkMutation,
} from 'types.d';

const DonorLabwork: FC = () => {
  const { t } = useTranslation('donor.DonorLabwork');
  const routineTestModal = useModal();
  const params = useParams();
  const shortDonorId = params.id || '';
  const pagination = usePagination();
  const breakpoints = Grid.useBreakpoint();
  const isBreakpointsEmpty = Object.keys(breakpoints).length === 0;
  const sort = useSort<LabworkListSort>();
  const navigate = useNavigate();
  const canUpdateLabwork =
    useCurrentUser().data?.permissions?.updateLabworkData || false;
  const donorQuery = useGetDonorQuery({
    variables: {
      input: {
        id: shortDonorId,
      },
    },
    onError: (err) => {
      message.error(err.message);
      if (err.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.IndividualDonors);
    },
  });
  const donorId = donorQuery.data?.getDonor.id;
  const labworkListVariables = {
    input: {
      take: config.PAGE_SIZE,
      skip: pagination.skip,
      sort: sort.options,
      filter: {
        donorId,
      },
    },
  };
  const getLabworkQuery = useGetLabworkListQuery({
    variables: labworkListVariables,
    onCompleted: (result) => {
      pagination.setTotal(result.getLabworkList.meta.total);
    },
    skip: !Boolean(donorId),
  });

  const [updateLabwork, updateLabworkMutation] = useUpdateLabworkMutation({
    refetchQueries: [
      { query: GET_LABWORK_LIST, variables: labworkListVariables },
    ],
    onCompleted: () => {
      message.success(t('labworkHasBeenSuccessfullyUpdated'));
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const [removeLabwork, removeLabworkMutation] = useRemoveLabworkMutation({
    refetchQueries: [
      { query: GET_LABWORK_LIST, variables: labworkListVariables },
    ],
    onCompleted: () => {
      message.success(t('labworkHasBeenDeleted'));
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const [updateLabworkStatus, updateLabworkStatusMutation] =
    useUpdateLabworkStatusMutation({
      refetchQueries: [
        { query: GET_LABWORK_LIST, variables: labworkListVariables },
      ],
      onCompleted: () => {
        message.success(t('labworkHasBeenSuccessfullyUpdated'));
      },
      onError: (error) => {
        message.error(error.message);
      },
    });

  const updateLabworkHandler = ({
    id,
    donor,
    collectionDate,
    DVMExam,
  }: LabworkOutput) => {
    updateLabwork({
      variables: {
        input: {
          id,
          DVMExam,
          donorId: donor.id,
          collectionDate: collectionDate,
        },
      },
    });
  };

  const removeLabworkHandler = (id: string) => {
    removeLabwork({
      variables: {
        input: {
          id,
        },
      },
      update(cache) {
        cache.evict({ fieldName: 'getLabworkList' });
      },
    });
  };

  const updateLabworkStatusHandler = (id: string, status: LabworkStatus) => {
    updateLabworkStatus({
      variables: {
        input: {
          id,
          status,
        },
      },
    });
  };

  const isLoading = [
    getLabworkQuery,
    updateLabworkMutation,
    updateLabworkStatusMutation,
    removeLabworkMutation,
    donorQuery,
  ].some(({ loading }) => loading);

  const labworks = getLabworkQuery.data?.getLabworkList.data as LabworkOutput[];

  const props = {
    labworks,
    updateLabworkStatusHandler,
    updateLabworkHandler,
    removeLabworkHandler,
    pagination,
    sort,
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[0, 10]}>
        {canUpdateLabwork && (
          <Col span={24}>
            <Button
              onClick={() => {
                routineTestModal.show();
              }}
            >
              {t('routineTest')}
            </Button>
          </Col>
        )}
        <Col span={24}>
          <RoutineTestModal {...routineTestModal.config} />
          {!isBreakpointsEmpty &&
            (breakpoints.md ? (
              <LabworkTable {...props} />
            ) : (
              <LabworkCardList {...props} />
            ))}
        </Col>
      </Row>
    </Spin>
  );
};
export default DonorLabwork;
