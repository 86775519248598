import { Col, message, Row } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { Button } from 'modules/common/components';
import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { DonationReportOutput, useGetDonationReportLazyQuery } from 'types.d';

import { donationReportHeaders } from './headers';

const DonationReports: FC = () => {
  const { t } = useTranslation('reports.DonationReports');
  const fileName = `donations_${format(new Date(), config.SHORT_DATE_FORMAT)}`;
  const [getDonationsReport, getDonationsReportQuery] =
    useGetDonationReportLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        input: {
          limit: 10000,
        },
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  const isLoading = getDonationsReportQuery.loading;
  const receivedDonations = (getDonationsReportQuery.data?.getDonationReports ||
    []) as DonationReportOutput[];

  const formattedDonations = receivedDonations.map((donation) => ({
    ...donation,
    donationDate: format(new Date(donation.donationDate), 'MM/dd/yyyy'),
  }));

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Button
          onClick={() => getDonationsReport()}
          loading={isLoading}
          disabled={isLoading}
          type="primary"
        >
          {t('generateCsvReport')}
        </Button>
      </Col>
      <Col span={24}>
        {Boolean(formattedDonations.length) && (
          <CSVLink
            headers={donationReportHeaders}
            data={formattedDonations}
            filename={fileName}
          >
            {`${fileName}.csv`}
          </CSVLink>
        )}
      </Col>
    </Row>
  );
};

export default DonationReports;
