import config from 'config';
import { Routes } from 'config/routes';
import AppointmentsHighlightProvider from 'modules/appointments/contexts/AppointmentsHighlight';
import CreateAppointmentsModalProvider from 'modules/appointments/contexts/CreateAppointmentsModal';
import EditAppointmentsModalProvider from 'modules/appointments/contexts/EditAppointmentModal';
import EnrollDonorModal from 'modules/appointments/contexts/EnrollDonorModal';
import AppointmentsPage from 'modules/appointments/pages/Appointments';
import TaskSchedulePage from 'modules/appointments/pages/TaskSchedule';
import { RouteType } from 'modules/auth/enums';
import { IModule } from 'modules/common/interfaces';
import { PermissionsEnum } from 'types.d';

import DaycareRegisterDetailProvider from './contexts/DaycareInfoModal';
import DaycareRegisterModalProvider from './contexts/DaycareRegisterModal';
import RecurringDateProvider from './contexts/RecurringDate';

const ownerModule: IModule = {
  routes: [
    ...(config.ENABLED_APPOINTMENTS_PAGE
      ? [
          {
            path: Routes.Appointments,
            Component: AppointmentsPage,
            type: RouteType.Auth,
            permissions: [PermissionsEnum.ViewAppointments],
          },
        ]
      : []),
    ...(config.ENABLED_TASK_SCHEDULE_PAGE
      ? [
          {
            path: Routes.Schedule,
            Component: TaskSchedulePage,
            type: RouteType.Auth,
            permissions: [
              PermissionsEnum.ViewTasksSchedule,
              PermissionsEnum.ViewAppointments,
            ],
          },
        ]
      : []),
  ],
  providers: [
    { Provider: AppointmentsHighlightProvider },
    { Provider: CreateAppointmentsModalProvider },
    { Provider: EnrollDonorModal },
    { Provider: EditAppointmentsModalProvider },
    { Provider: DaycareRegisterModalProvider },
    { Provider: DaycareRegisterDetailProvider },
    { Provider: RecurringDateProvider },
  ],
};

export default ownerModule;
