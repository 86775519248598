import { gql } from '@apollo/client';

export const REGION_FRAGMENT = gql`
  fragment Region on RegionOutput {
    id
    createdAt
    updatedAt
    name
    accountingCode
  }
`;

export const LOCATION_FRAGMENT = gql`
  fragment Location on LocationOutput {
    id
    createdAt
    updatedAt
    name
    type
    region {
      ...Region
    }
  }
  ${REGION_FRAGMENT}
`;
