export enum Routes {
  Login = '/login',
  NotFound = '/not-found',
  Profile = '/profile',
  ProfileAccount = '/profile/account',
  ProfileNotifications = '/profile/notifications',
  UserNotifications = '/user/notifications',
  SignUp = '/signup',
  ResetPassword = '/reset-password/:code',
  ForgotPassword = '/forgot-password',
  EmailConfirmation = '/email-confirmation/:emailConfirmationCode',
  Users = '/users',
  InviteUsers = '/users/invite',
  UsersPermissions = '/users/permissions',
  EditUsers = '/users/:id',
  AcceptInvite = '/accept-invite/:id',
  Donors = '/donors',
  AppointmentsDonors = '/donors/appointments',
  Appointments = '/appointments',
  DonorsApplication = '/donors/application',
  CreateDonors = '/donors/create',
  DonorCharts = '/donors/charts/:id',
  Donations = '/donations',
  CreateDonation = '/donations/create',
  EditDonation = '/donations/:id',
  EditDonationAdditionalInfo = '/donations/additional-info/:id',
  DonationAdditionalInfo = '/donations/additional-info',
  Requests = '/requests',
  Request = '/requests/:id',
  Slots = '/slots',
  Schedule = '/schedule',
  IndividualDonors = '/donors/individual',
  GroupDonors = '/donors/group',
  GroupProfile = '/group/:id',
  GroupProfileTeams = '/group/teams/:id',
  Group = '/group',
  Teams = '/teams',
  ReserveDonors = '/donors/reserve',
  DonorsProfile = '/donors/:id',
  DonorsPictures = '/donors/pictures/:id',
  DonorsNotes = '/donors/notes/:id',
  DonorsLabworkBy = '/donors/labwork/:id',
  DonorsDonations = '/donors/donations/:id',
  DonorsVitalsChart = '/donors/charts/:id',
  Pictures = '/pictures',
  Notes = '/notes',
  Labwork = '/labwork',
  Charts = '/charts',
  Owners = '/owners',
  CreateOwner = '/owners/create',
  EditOwner = '/owners/:id',
  OwnerAttachment = '/owners/attachment/:id',
  CollectionDonations = '/donations/collection',
  Products = '/products',
  Product = '/products/:id',
  CreateProducts = '/products/create',
  ProductsDashboard = '/products/dashboard',
  Orders = '/orders',
  Reports = '/reports',
  DonorsReports = '/reports/donors',
  DonationReports = '/reports/donations',
  OwnersReports = '/reports/owners',
  IneligiblesReports = '/reports/ineligibles',
  ProductsReports = '/reports/products',
  RegisterPet = '/register-pet',
  ResendConfirmationCode = '/resend-confirmation-code',
  Locations = '/locations',
  Regions = '/locations/regions',
  PrivacyPolicy = '/privacy-policy',
}
