import { Col, Row, Modal, ModalProps, List } from 'antd';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DonorStatus,
  useGetAppointmentsDatesForDonorLazyQuery,
  useGetLabworkListLazyQuery,
  useGetSpecificDonorDonationsListLazyQuery,
} from 'types.d';

import styles from './styles.module.scss';

export type AppointmentInterval = {
  start: string;
  end: string;
};

type PropTypes = {
  donorId: string;
  donorStatus?: DonorStatus;
} & ModalProps & { hide: () => void };

export const DonorArchiveConfirmationModal: FC<PropTypes> = ({
  hide,
  visible,
  donorId,
  donorStatus,
  ...restProps
}) => {
  const { t } = useTranslation('donor.EditDonorForm.ConfirmArchiveModal');
  const isArchived = donorStatus === DonorStatus.Archived;

  const [getDonations, donationsQuery] =
    useGetSpecificDonorDonationsListLazyQuery();
  const [getLabworks, labworksQuery] = useGetLabworkListLazyQuery();
  const [getAppointments, appointmentsQuery] =
    useGetAppointmentsDatesForDonorLazyQuery();

  useEffect(() => {
    if (visible && donorId) {
      getDonations({
        variables: {
          input: {
            filter: {
              donorId,
            },
          },
        },
      });

      getLabworks({
        variables: {
          input: {
            filter: {
              donorId,
            },
          },
        },
      });

      getAppointments({
        variables: {
          donorId,
        },
      });
    }
  }, [visible]);

  const appointmentsList = appointmentsQuery.data?.getAppointmentsDatesForDonor
    ? appointmentsQuery.data?.getAppointmentsDatesForDonor.map(
        (appointment) => {
          const start = new Date(appointment.start);
          const end = new Date(appointment.end);
          const startFormatted = format(start, config.DATE_AND_TIME_FORMAT);
          const endFormatted = format(end, config.DATE_AND_TIME_FORMAT);

          return (
            <List.Item>
              {config.ENABLED_APPOINTMENTS_PAGE ? (
                <Link to={Routes.Appointments}>
                  {startFormatted} - {endFormatted}
                </Link>
              ) : (
                <>
                  {startFormatted} - {endFormatted}
                </>
              )}
            </List.Item>
          );
        }
      )
    : [];

  const totalDonation =
    donationsQuery.data?.getSpecificDonorDonationsList.meta.total;
  const totalLabworks = labworksQuery.data?.getLabworkList.meta.total;

  return (
    <Modal
      title="Archive donor"
      visible={visible}
      onOk={hide}
      okButtonProps={{ className: isArchived ? styles.none : '' }}
      onCancel={hide}
      okText={t('archive')}
      cancelText={t('cancel')}
      {...restProps}
    >
      <p>{t('donorWontBeDeleted')} </p>
      {donationsQuery.data?.getSpecificDonorDonationsList && !!totalDonation && (
        <Row>
          <Col>
            <Link to={Routes.DonorsDonations.replace(':id', donorId)}>
              {t('donorContOfDonations', { count: totalDonation })}
            </Link>
          </Col>
        </Row>
      )}
      {labworksQuery.data?.getLabworkList && !!totalLabworks && (
        <Row>
          <Col>
            <Link to={Routes.DonorsLabworkBy.replace(':id', donorId)}>
              {t('donorContOfLabworks', { count: totalLabworks })}
            </Link>
          </Col>
        </Row>
      )}
      {!!appointmentsList.length && (
        <Row>
          <Row>Appointments:</Row>
          <List>{appointmentsList}</List>
        </Row>
      )}
      {!isArchived && <p>{t('sureToArchive')}</p>}
    </Modal>
  );
};
