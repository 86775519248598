import { PolicyPrivacyContentLang } from 'modules/user/types';
import { FC, ReactNode } from 'react';

import EngPolicyContent from './EngPolicyContent';

const { Eng } = PolicyPrivacyContentLang;

type PropTypes = {
  lang?: PolicyPrivacyContentLang;
};

const contentMap: Record<PolicyPrivacyContentLang, ReactNode> = {
  [Eng]: <EngPolicyContent />,
};

const PrivacyPolicyContent: FC<PropTypes> = ({ lang = Eng }) => {
  return <>{contentMap[lang]}</>;
};

export default PrivacyPolicyContent;
