import { gql } from '@apollo/client';
import { REGION_FRAGMENT } from 'modules/locations/graphql/fragments';

export const PERMISSION_FRAGMENT = gql`
  fragment Permission on PermissionsOutput {
    id
    value
    permission
  }
`;

export const ROLE_OUTPUT_FRAGMENT = gql`
  fragment Role on RoleOutput {
    id
    value
  }
`;

export const ROLE_WITHOUT_PERMISSIONS_OUTPUT_FRAGMENT = gql`
  fragment RoleWithoutPermissions on RoleWithoutPermissionsOutput {
    id
    value
  }
`;

export const USER_OUTPUT_FRAGMENT = gql`
  fragment User on UserOutput {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    productRegionPreference {
      ...Region
    }
    birthday
    shortId
    type
    isArchived
    deletionIntentionAt
    contactPreferences
    deletedAt
  }
  ${REGION_FRAGMENT}
`;

export const NOTIFICATION_USER_OUTPUT_FRAGMENT = gql`
  fragment NotificationUser on NotificationUserOutput {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    shortId
    type
    isArchived
    contactPreferences
  }
`;

export const CONTACT_OUTPUT_FRAGMENT = gql`
  fragment Contact on ContactOutput {
    streetAddress
    city
    state
    zipCode
    email
    firstName
    id
    lastName
    phoneNumber
  }
`;
