import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, message, Row, Typography } from 'antd';
import { Routes } from 'config/routes';
import {
  Spin,
  Button,
  Link,
  ControlledCheckbox,
} from 'modules/common/components';
import { convertPoundsToKilograms } from 'modules/donor/utils/convertWeight';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  LocationType,
  useGetPublicLocationsQuery,
  useSignUpWithDonorMutation,
} from 'types.d';

import { getPetFields } from './fields/petFields';
import { userFields } from './fields/userFields';
import FormSection from './FormSection';
import { registerPetSchema, RegisterPetSchemaType } from './schema';
import styles from './styles.module.scss';

const { Paragraph } = Typography;

const RegisterPetForm: FC = () => {
  const { t } = useTranslation('donor.RegisterPetForm');
  const registerPetForm = useForm<RegisterPetSchemaType>({
    resolver: yupResolver(registerPetSchema),
    mode: 'onChange',
  });
  const specie = registerPetForm.watch('pet.species');
  const weight = registerPetForm.watch('pet.weight');
  const region = registerPetForm.watch('pet.region');

  const petFields = useMemo(
    () => getPetFields({ donorSpecie: specie, region }),
    [specie, region]
  );

  useEffect(() => {
    registerPetForm.resetField('pet.location');
  }, [region]);

  const { data } = useGetPublicLocationsQuery({
    variables: {
      input: {
        take: 100,
      },
    },
  });

  const isDaycareLocationSelected = useMemo(() => {
    return Boolean(
      data?.getPublicLocations.data.find(
        (l) =>
          l.name === registerPetForm.getValues().pet?.location &&
          l.type === LocationType.ForDaycareOwners
      )
    );
  }, [registerPetForm.watch().pet?.location]);

  const validateAttendDaycareFieldsValid = () => {
    if (
      !registerPetForm.getValues().pet.wontRegularlyAttendDaycare &&
      !registerPetForm.getValues().pet.attendDaycareDays?.length &&
      isDaycareLocationSelected
    ) {
      registerPetForm.setError(
        'pet.attendDaycareDays',
        {
          message: t('attendDaycareDaysValidationError'),
        },
        { shouldFocus: true }
      );

      return true;
    }
    registerPetForm.clearErrors('pet.attendDaycareDays');
    return false;
  };

  useEffect(() => {
    registerPetForm.setValue('pet.attendDaycareDays', undefined);
    registerPetForm.setValue('pet.wontRegularlyAttendDaycare', false);
  }, [registerPetForm.watch().pet?.location]);

  useEffect(() => {
    registerPetForm.setValue('pet.attendDaycareDays', undefined);
    validateAttendDaycareFieldsValid();
  }, [registerPetForm.watch().pet?.wontRegularlyAttendDaycare]);

  useEffect(() => {
    if (weight) {
      registerPetForm.trigger('pet.weight');
    }
  }, [specie, weight]);

  const [signUpWithDonor, signUpWithDonorMutation] = useSignUpWithDonorMutation(
    {
      onCompleted: (data) => {
        window.scrollTo(0, 0);
        const email = registerPetForm.getValues('user.email');
        const messageText = data.signUpWithDonor
          ? t('checkYourEmail', { email })
          : t('sentEmail');
        message.success(messageText);
        window.scrollTo(0, 0);
        registerPetForm.reset();
      },
      onError: (error) => {
        message.error(error.message);
      },
    }
  );
  const submit = registerPetForm.handleSubmit(({ pet, user }) => {
    if (validateAttendDaycareFieldsValid()) {
      return;
    }

    const { location, wontRegularlyAttendDaycare, region, ...retPetField } =
      pet;
    signUpWithDonor({
      variables: {
        input: {
          user: {
            ...user,
            locationPreferences: location,
          },
          donor: {
            ...retPetField,
            location,
            weight: convertPoundsToKilograms(pet.weight),
            canRegularlyAttendDaycare: !wontRegularlyAttendDaycare,
          },
        },
      },
    });
  });

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <FormProvider {...registerPetForm}>
          <Spin spinning={signUpWithDonorMutation.loading}>
            <Form>
              <FormSection
                fields={petFields}
                fieldNamePrefix="pet"
                isDaycareLocationSelected={isDaycareLocationSelected}
              />
              <FormSection fields={userFields} fieldNamePrefix="user" />
              <Col span={24}>
                <Paragraph>
                  You are registering your pet to be screened and join the
                  volunteer donation pool of K9 blood donors for North American
                  Veterinary Blood Bank. By submitting this for you are
                  consenting to allowing NAVBB to screen your pet for bloodborne
                  pathogens and blood type your dog to become a K9 blood donor.
                </Paragraph>
              </Col>
              <Col span={24}>
                <ControlledCheckbox
                  controlled={{
                    control: registerPetForm.control,
                    name: 'privacyPolicyAgreement',
                  }}
                >
                  <Paragraph>
                    {t('agreeToNavbb')}{' '}
                    <Link to={Routes.PrivacyPolicy} target="_blank">
                      {t('privacyPolicy')}
                    </Link>
                  </Paragraph>
                </ControlledCheckbox>
              </Col>
              <Button
                size="large"
                type="primary"
                className={styles.submitButton}
                onClick={submit}
                disabled={!registerPetForm.formState.isValid}
              >
                {t('submit')}
              </Button>
            </Form>
          </Spin>
        </FormProvider>
      </Col>
    </Row>
  );
};

export default RegisterPetForm;
