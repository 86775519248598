import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Col, Row, message, Form } from 'antd';
import { FormProvider } from 'antd/es/form/context';
import { Routes } from 'config/routes';
import { FormItem, Button, Spin, Alert } from 'modules/common/components';
import { getIsRequiredInSchema } from 'modules/common/utils/yup/getIsRequiredInSchema';
import { getAdditionalDonationInfoFields } from 'modules/donations/components/AdditionalDonationInfoForm/fields';
import { GET_DONATION_QUERY } from 'modules/donations/graphql/queries';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useState, useMemo } from 'react';
import { useForm, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useGetDonationQuery,
  AdditionalDonationInfoOutput,
  useCreateAdditionalDonationInfoMutation,
  useUpdateAdditionalDonationInfoMutation,
  DonorStatus,
  PermissionsEnum,
} from 'types.d';

import {
  additionalDonationInfoSchema,
  AdditionalDonationInfoSchema,
} from './schema';
import styles from './styles.module.scss';

const AdditionalDonationInfoForm: FC = () => {
  const { t } = useTranslation('donation.AdditionalDonationInfoForm');
  const currentUser = useCurrentUser();
  const canUpdateDonation = currentUser.isCan(
    PermissionsEnum.UpdateDonationData
  );
  const params = useParams();
  const navigate = useNavigate();
  const donationId = params.id || '';
  const isRequired = getIsRequiredInSchema(additionalDonationInfoSchema);
  const additionalDonationInfoForm = useForm<AdditionalDonationInfoSchema>({
    resolver: yupResolver(additionalDonationInfoSchema),
    mode: 'onChange',
  });

  const [formEnabled, setFormEnabled] = useState<boolean>(true);
  const [shouldCreate, setShouldCreate] = useState<boolean>(true);
  const [
    createAdditionalDonationInfo,
    { loading: loadingCreatingAdditionalDonationInfo },
  ] = useCreateAdditionalDonationInfoMutation({
    refetchQueries: [GET_DONATION_QUERY],
    onCompleted: (data) => {
      autofillForm(
        data.createAdditionalDonationInfo as AdditionalDonationInfoOutput
      );
      message.success(t('additionalDonationInfoCreated'));
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const autofillForm = (additionalInfo: AdditionalDonationInfoOutput) => {
    additionalDonationInfoForm.reset({
      numberOfCollectionUnitsUsed: additionalInfo.numberOfCollectionUnitsUsed,
      veinUsed: additionalInfo.veinUsed,
      vascularStructure: additionalInfo.vascularStructure || undefined,
      flowRate: additionalInfo.flowRate || undefined,
      donationPosition: additionalInfo.donationPosition || undefined,
      holdersPosition: additionalInfo.holdersPosition || undefined,
      donorMovement: additionalInfo.donorMovement || undefined,
      typeOfMovement: additionalInfo.typeOfMovement || undefined,
      emotionalSupport: additionalInfo.emotionalSupport || undefined,
      dislikes: additionalInfo.dislikes || undefined,
      likes: additionalInfo.likes || undefined,
      treatPreferences: additionalInfo.treatPreferences || undefined,
      primaryHolder: additionalInfo?.primaryHolder?.id,
      secondaryHolder: additionalInfo.secondaryHolder?.id,
      poker: additionalInfo?.poker?.id,
    });
  };

  const getDonationQuery = useGetDonationQuery({
    variables: {
      input: {
        id: donationId,
      },
    },
    onCompleted: (data) => {
      const additionalInfo = data.getDonation.additionalInfo;

      if (additionalInfo) {
        autofillForm(additionalInfo as AdditionalDonationInfoOutput);
        setShouldCreate(false);
        setFormEnabled(false);
      } else setShouldCreate(true);
    },
    onError() {
      navigate(Routes.NotFound);
    },
  });
  const isDonorArchived =
    getDonationQuery?.data?.getDonation.donor.status === DonorStatus.Archived;

  const [
    updateAdditionalDonationInfo,
    { loading: loadingUpdatingAdditionalDonationInfo },
  ] = useUpdateAdditionalDonationInfoMutation({
    refetchQueries: [GET_DONATION_QUERY],
    onCompleted: (data) => {
      autofillForm(
        data.updateAdditionalDonationInfo as AdditionalDonationInfoOutput
      );
      message.success(t('additionalDonationInfoUpdated'));
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const editHandler = () => {
    autofillForm(
      getDonationQuery.data?.getDonation
        .additionalInfo as AdditionalDonationInfoOutput
    );
    setFormEnabled(true);
  };

  const closeHandler = () => {
    setFormEnabled(false);
    autofillForm(
      getDonationQuery.data?.getDonation
        .additionalInfo as AdditionalDonationInfoOutput
    );
  };

  const saveHandler = additionalDonationInfoForm.handleSubmit(
    async (formData) => {
      const { poker, secondaryHolder, primaryHolder, ...otherFormData } =
        formData;
      if (shouldCreate) {
        await createAdditionalDonationInfo({
          variables: {
            input: {
              ...otherFormData,
              donationId: donationId,
              pokerId: poker,
              secondaryHolderId: secondaryHolder,
              primaryHolderId: primaryHolder,
            },
          },
        });
      } else {
        const donation = getDonationQuery.data!.getDonation!;
        await updateAdditionalDonationInfo({
          variables: {
            input: {
              id: donation.additionalInfo!.id,
              ...otherFormData,
              pokerId: poker,
              donationId: donation.id,
              secondaryHolderId: secondaryHolder,
              primaryHolderId: primaryHolder,
            },
          },
        });
      }

      setFormEnabled(false);
    }
  );

  const fields = useMemo(
    () => getAdditionalDonationInfoFields({ enabled: formEnabled }),
    [formEnabled]
  );

  const loading =
    loadingUpdatingAdditionalDonationInfo ||
    loadingCreatingAdditionalDonationInfo;

  return (
    <Spin spinning={loading}>
      {isDonorArchived && (
        <Alert
          type="error"
          message="Donor si archived"
          className={styles.alert}
        />
      )}
      <Col>
        <FormProvider {...additionalDonationInfoForm}>
          <Form>
            <Row justify="space-between">
              {fields.map(({ name, Component, label }) => {
                const error = additionalDonationInfoForm.formState.errors[
                  name
                ] as FieldError | undefined;
                const required = isRequired(name);

                return (
                  <Col xs={24} sm={24} md={24} lg={11} xl={11} key={name}>
                    <Col>
                      <FormItem
                        className={styles.formItem}
                        extra={error?.message}
                        validateStatus={error?.message && 'error'}
                        label={label}
                        required={required}
                      >
                        <Component
                          control={additionalDonationInfoForm.control}
                          name={name}
                        />
                      </FormItem>
                    </Col>
                  </Col>
                );
              })}
            </Row>
            {canUpdateDonation && (
              <Row justify="space-between">
                <Col>
                  <Button disabled={!formEnabled} onClick={closeHandler}>
                    {t('close')}
                  </Button>
                </Col>
                <Col>
                  {formEnabled && (
                    <Button
                      type="primary"
                      disabled={
                        !additionalDonationInfoForm.formState.isValid ||
                        !additionalDonationInfoForm.formState.isDirty
                      }
                      onClick={saveHandler}
                    >
                      {t('save')}
                    </Button>
                  )}

                  {!formEnabled && !shouldCreate && (
                    <Button
                      type="primary"
                      disabled={isDonorArchived}
                      onClick={editHandler}
                    >
                      {t('edit')}
                    </Button>
                  )}
                </Col>
              </Row>
            )}
          </Form>
        </FormProvider>
      </Col>
    </Spin>
  );
};

export default AdditionalDonationInfoForm;
