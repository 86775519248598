export const profiles = [
  {
    id: '12949999',
    description: 'Young Adult Profile—Chem 11 w/ IDEXX SDMA®, IDEXX CBC™',
  },
  {
    id: '12729999',
    description: 'Adult Wellness—Chem 21 w/ IDEXX SDMA®, IDEXX CBC™',
  },
  {
    id: '18509999',
    description: 'Adult Screen—Chem 25 w/ IDEXX SDMA®, IDEXX CBC™, UA',
  },
  {
    id: '19999',
    description: 'HealthChek™ Profile—Chem 25 w/ IDEXX SDMA®, IDEXX CBC™',
  },
  {
    id: '23379999',
    description: 'HealthChek™ Profile with Spec cPL® Test',
  },
  {
    id: '469999',
    description:
      'HealthChek™ Plus Profile—Chem 25 w/ IDEXX SDMA®, IDEXX CBC™, TT4',
  },
  {
    id: '27329999',
    description:
      'Feline ADRCheck® Plus with Spec fPL® Test—Chem 25 w/ IDEXX SDMA®, IDEXX CBC™, Spec fPL Test, TT4',
  },
  {
    id: '8859999',
    description:
      'Feline Comprehensive Profile—Chem 27 w/ IDEXX SDMA®, IDEXX CBC™, FCoV, FeLV, FIV, TT4',
  },
  {
    id: '10139999',
    description: 'Total Health™ Profile—Chem 27 w/ IDEXX SDMA®, IDEXX CBC™',
  },
  {
    id: '759999',
    description:
      'Total Health™ Plus Profile—Chem 27 w/ IDEXX SDMA®, IDEXX CBC™, TT4',
  },
];
